<style >
    .th-fixed-width {
        width: 200px !important;
    }
    .td-fxd-wdth {
        width: 72px !important;
    }
    .td-actn-fxd-wdth {
        width: 150px !important
    }
    .grp-text-area-cust {
	    margin-bottom: 5px !important;
    }
    .grp-text-area-cust textarea {
        resize: none;
        height: 35px;
    }
    .attachment-w-required {
        margin-bottom: 10px;
        border-radius: 5px; 
        padding: 5px;
        background-color:#ff4136;
        color: white; 
        text-align: center;
    }
    .attachment-n-required {
        margin-bottom: 10px;
        border-radius: 5px; 
        padding: 5px;
        background-color:#03a9f4;
        color: white; 
        text-align: center;
    }
    .parent-row td {
        border-left: none;  /* Remove left border of each cell */
        border-right: none; /* Remove right border of each cell */
        cursor: pointer;
    }
    .parent-row-light {
        background: #79d5b9;
    }
    .parent-row-light:hover {
        background: #79d5b9 !important;
    }
    .parent-row-dark {
        background: #2a2173;
    }
    .parent-row-dark:hover {
        background: #2a2173 !important;
    }
    .parent-row-light td:first-child {
        border-left: 1px solid #D8DBE0; /* Add left border to the first cell */
    }
    .parent-row-light td:last-child {
        border-right: 1px solid #D8DBE0; /* Add right border to the last cell */
    }
    .parent-row-dark td:first-child {
        border-left: 1px solid #3A3B44; /* Add left border to the first cell */
    }
    .parent-row-dark td:last-child {
        border-right: 1px solid #3A3B44; /* Add right border to the last cell */
    }

    .parent-row-ext td {
        border-left: none;  /* Remove left border of each cell */
        border-right: none; /* Remove right border of each cell */
        cursor: pointer;
    }
    .parent-row-ext-light td:first-child {
        border-left: 1px solid #D8DBE0; /* Add left border to the first cell */
    }
    .parent-row-ext-light td:last-child {
        border-right: 1px solid #D8DBE0; /* Add right border to the last cell */
    }
    .parent-row-ext-dark td:first-child {
        border-left: 1px solid #3A3B44; /* Add left border to the first cell */
    }
    .parent-row-ext-dark td:last-child {
        border-right: 1px solid #3A3B44; /* Add right border to the last cell */
    }

    .cstm-btn{
        border: 0 !important;
        box-shadow: none !important;
        border-radius: 2px !important;
    }
    .cstm-btn:hover {
        border: 0 !important;
        box-shadow: none !important;
        border-radius: 2px !important;
    }
    .cstm-btn:active {
        border: 0 !important;
        box-shadow: none !important;
        border-radius: 2px !important;
    }
    .cstm-btn:focus {
        border: 0 !important;
        box-shadow: none !important;
        border-radius: 2px !important;
    }
    .custom-row {
        border: none !important;
        font-size: 16px; /* Optional: increase font size */
        white-space: nowrap; /* Optional: prevent text from wrapping */
        background-color: #f8f9fa; /* Optional: set background color */
      }
    
</style>
<template>
    <div class="wrapper">
        <CCol xl="12" lg="12" md="12" sm="12">
            <CCard>
                <CCardHeader>
                    <font-awesome-icon icon="paper-plane"/>  New Document Routing
                    
                    <!-- :disabled="warnings.length > 0 || !isValid" -->
                    <!-- @click="warnings.length <= 0 ? saveAsDraft() : ''" -->
                    <CButton 
                        class="float-right"
                        type="submit" 
                        size="sm"
                        color="primary"
                        @click="saveAsDraft()"
                    >
                        <font-awesome-icon icon="save"/> Save as Draft
                    </CButton>
                    
                    <!-- :disabled="warnings.length > 0 || !isValid" -->
                    <!-- @click="warnings.length <= 0 && isValid ? startRouting() : ''" -->
                    <CButton 
                        class="float-right"
                        size="sm"
                        style="margin-right:10px"
                        type="submit" 
                        color="warning"
                        @click="startRouting()"
                    >
                        <font-awesome-icon icon="share-square"/> Start Routing
                    </CButton>

                    <a-popover title="WARNING!" placement="left">
                        <template slot="content">
                            <span v-for="(item, index) in warnings" v-bind:key="index">
                                {{index + 1}}. {{item}} <br>
                            </span>
                        </template>
                        <font-awesome-icon 
                            v-if="warnings.length > 0"
                            style="color: orange; margin-right:10px" 
                            icon="triangle-exclamation" 
                            class="float-right" 
                            size="2xl"/>
                    </a-popover>
                    
                </CCardHeader>
                <CCardBody>
                    <CRow>
                        <CCol lg="6">
                            <CRow>
                                <CCol lg="12">
                                    <CInput
                                        :disabled="reference_from_form_data"
                                        :lazy="false"
                                        :value.sync="$v.routing.reference_number.$model"
                                        :isValid="checkIfValid('reference_number')"
                                        label="Reference Number" 
                                        type="text" 
                                        placeholder="100-8953245" 
                                        autocomplete="off"  
                                        ondrop="return false;" 
                                        v-tocapitalize
                                        v-nospecialcharacter
                                        v-model="routing.reference_number"
                                        invalidFeedback="Must be more than five(5) characters."
                                        :validFeedback="reference_from_form_data ? 'Link in Form Application. Reference Number Unable to change.' : ''"
                                    />
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol lg="12">
                                    <CTextarea
                                        label="Description" 
                                        type="text" 
                                        placeholder="Enter brief description..." 
                                        autocomplete="off"  
                                        v-tocapitalize
                                        invalidFeedback="Must be more than five(5) characters."
                                        v-model="routing.description"
                                    />
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol lg="12">
                                    <label>Signatory Template</label>
                                    <v-select 
                                        label="name"
                                        :options="user_templates_lists"
                                        :class="selected_user_template ? (!selected_user_template.id ? 'has-error' : 'has-success') : 'has-error'"
                                        v-model="selected_user_template"
                                    />
                                </CCol>
                            </CRow>
                        </CCol>
                        <CCol lg="6">
                            <template v-if="can_upload_file_via_url">
                                <CRow>
                                    <CCol lg="12">
                                        <CAlert show color="info">
                                            <h5 class="alert-heading"><font-awesome-icon icon="link"/> Upload file using API.</h5>
                                            <hr>
                                            <p class="mb-0">
                                                <CButton 
                                                    style="margin-bottom:-4px;"
                                                    size='sm'    
                                                    color="info" 
                                                    @click="openUploadFileViaURLModal()"
                                                >
                                                    <font-awesome-icon icon="file"/> Get file
                                                </CButton>
                                            </p>
                                        </CAlert>
                                    </CCol>
                                </CRow>
                            </template>
                            <CRow>
                                <CCol lg="12" v-show="!can_upload_file_via_url">
                                    <label><font-awesome-icon icon="paperclip"/> Upload PDF to Sign &nbsp;</label>
                                    <a-popover title="NOTE!" placement="left">
                                        <template slot="content">
                                            <small>Only upload the PDF that will be sign during routing.</small>
                                        </template>
                                        <font-awesome-icon style="color: #4caf50; " icon="info-circle" size="lg"/>
                                    </a-popover>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol lg="12">
                                    <VueFileAgent 
                                        ref="_templateFile"
                                        :theme="'list'" 
                                        :multiple="true"
                                        :maxFiles="main_file_via_url || main_file_via_form_data ? this.template_file.length : 10"
                                        :maxSize="'8MB'"
                                        :deletable="true"
                                        :meta="true"
                                        :accept="'application/pdf'"
                                        :linkable="main_file_via_url ? true : false"
                                        @beforedelete="deleteTemplateFile($event)"
                                        v-model="template_file"
                                        :readonly="can_upload_file_via_url"
                                        >
                                    </VueFileAgent>
                                </CCol>
                            </CRow>
                            <br>
                            <CRow v-if="list_of_attachments.length > 0">
                                <CCol lg="12">
                                    <CButton 
                                        @click="attachment_checklist_collapsed = !attachment_checklist_collapsed" 
                                        :color="attachment_checklist_collapsed ? 'secondary' : 'info'"
                                        class="mb-2"
                                    >
                                    <font-awesome-icon :icon="attachment_checklist_collapsed ? 'eye-slash' : 'eye'"/>    
                                    {{ attachment_checklist_collapsed ? 'Hide Attachments Checklist' : 'Show Attachment Checlist' }}
                                    </CButton>
                                    <CCollapse :show="attachment_checklist_collapsed">
                                        <CDataTable
                                            :items="attachment_display_data"
                                            :fields="attachments_display_field"
                                            border
                                            size="sm"
                                            hover
                                        >
                                            <template #name="{item}">
                                                <td>
                                                    {{ item.name }}
                                                </td>
                                            </template>
                                            <template #remarks="{item}">
                                                <template v-if="parseInt(item.required) == 1">
                                                    <td style="color:red">
                                                        <strong>Required</strong>
                                                    </td>
                                                </template>
                                                <template v-else>
                                                    <td>
                                                        <i>Optional</i>
                                                    </td>
                                                </template>
                                            </template>
                                            <template #status="{item}">
                                               <!-- <td :class="item.status == 'used' ? 'checklist-used' : 'checklist-unuse'" style="padding: 0 0 0 5px !important;">
                                                    <font-awesome-icon
                                                        style="font-size: 30px;"
                                                        :icon="item.status == 'used' ? 'square-check' : 'square-xmark'"
                                                    /> 
                                               </td> -->
                                               <td :class="item.status == 'used' ? 'checklist-used' : 'checklist-unuse'" style="padding: 0 0 0 5px !important;">
                                                    <font-awesome-icon
                                                        size="lg"
                                                        :icon="item.status == 'used' ? 'square-check' : 'square-xmark'"
                                                    /> 
                                                    <!-- style="font-size: 30px;" -->
    
                                                    &nbsp;
                                                    <i>{{ item.status == 'used' ? 'Document added.' : 'No document added.' }}</i>
                                                </td>
                                            </template>
                                        </CDataTable>
                                    </CCollapse>
                                </CCol>
                            </CRow>
                        </CCol>
                    </CRow>
                </CCardBody>
            </CCard>
        </CCol>
        <CCol xl="12" lg="12" md="12" sm="12">
            <CCard >
                <CCardBody>
                    <CRow>
                        <CCol lg="12">
                            <CTabs variant="pills" justified :active-tab="active_tab" @update:activeTab="changeTab">
                                <br>
                                <CTab >
                                    <template slot="title">
                                        Signatories & Requirements 
                                        <template v-if="!using_new_template_version">
                                            <CBadge color="success" v-if="old_signatories_counter > 0">( {{old_signatories_counter}} )</CBadge>
                                        </template>
                                        <template v-else>
                                            <CBadge color="success" v-if="selected_signatory_nv_counter > 0">( {{selected_signatory_nv_counter}} )</CBadge>
                                        </template>
                                    </template>
                                    
                                    <template v-if="signatory_locked">
                                        <CRow>
                                            <CCol lg="12">
                                                <CAlert show color="danger">
                                                    <h4 class="alert-heading"><font-awesome-icon icon="lock"/> Template Signatory Lock!</h4>
                                                    <hr>
                                                    <p class="mb-0">
                                                        Once a routing has been made, the signatory cannot be removed or changed.
                                                    </p>
                                                </CAlert>
                                            </CCol>
                                        </CRow>
                                    </template>
                                    <CRow>
                                        <CCol lg="12">
                                            <!-- <CButton 
                                                v-if="using_new_template_version"
                                                color="warning" 
                                                class="float-left" 
                                                @click="showSignatorySelection()" 
                                            >
                                                <font-awesome-icon icon="signature"/> Show Signatory Selection
                                            </CButton> -->
                                            <template v-if="using_new_template_version">
                                                <CButton 
                                                    v-show="new_version_signatories_v2.length > 0"
                                                    class="float-right"
                                                    color="info"
                                                    @click="foldSignatories()"
                                                >
                                                    <font-awesome-icon :icon="fold_signatories ? 'eye-slash' : 'eye'"/> 
                                                    {{fold_signatories ? 'Unfold Signatories' : 'Fold Signatories'}}
                                                </CButton>
                                                <CDataTable
                                                    :items="new_version_signatories_v2"
                                                    :fields="new_version_signatories_field"
                                                    border
                                                    hover
                                                    sorter
                                                    @row-clicked="parentRowClicked"
                                                    size="sm"
                                                >
                                                <template #name="{item, index}">
                                                    <template v-if="item.parent">
                                                        <td>
                                                            <h6>
                                                                {{item.purpose_new}}
                                                                <CBadge >( {{ showCounter(item) }} ) 
                                                                </CBadge>
                                                                    
                                                            </h6>
                                                        </td>
                                                    </template>
                                                        <template v-else>
                                                            <template v-if="!item.hidden">
                                                                <td>
                                                                    <strong>{{item.name}}</strong>
                                                                    <br>
                                                                    <br>
                                                                    <CRow>
                                                                        <CCol lg="12" >
                                                                            <span v-for="(reqs, reqs_index) in item.document_signature_requirements" v-bind:key="reqs_index">
                                                                                <!-- value="DELL" -->
                                                                                <p-check 
                                                                                    :value="reqs.id+'@param@'+item.id+'@param@'+reqs.requirement+'@param@'+item?.user?.name"
                                                                                    class="p-icon p-bigger"
                                                                                    v-model="checked_requirements_id_new_version" 
                                                                                    color="success" >
                                                                                    <font-awesome-icon class="icon" icon="check"/>
                                                                                    {{ reqs.requirement }}
                                                                                </p-check>
                                                                            </span>
                                                                        </CCol>
                                                                    </CRow>
                                                                </td>
                                                            </template>
                                                            <template v-else>
                                                            </template>
                                                        </template>
                                                </template>
                                                <template #order="{item}">
                                                    <template v-if="!item.parent">
                                                        <template v-if="!item.hidden">
                                                            <td>
                                                                {{ item.order }}
                                                            </td>
                                                        </template>
                                                        <template v-else>
                                                        </template>
                                                    </template>
                                                    <template v-else>
                                                        <td></td>
                                                    </template>
                                                </template>
                                                </CDataTable>
                                            </template>
                                            <template v-else>
                                                <template v-if="selected_user_template ? selected_user_template.document_template_signatures.length > 1 : false">
                                                    <CButton 
                                                        color="danger" 
                                                        class="float-right" 
                                                        @click="toggleEdit()" 
                                                        v-show="editTemplate"
                                                    >
                                                        <font-awesome-icon icon="window-close"/> Cancel
                                                    </CButton>
    
                                                    <CButton 
                                                        color="success" 
                                                        class="float-right" 
                                                        v-show="editTemplate" 
                                                        @click="confirmChanges()"
                                                    >
                                                        <font-awesome-icon icon="save"/> Confirm Changes
                                                    </CButton>
    
                                                    <CButton 
                                                        color="info" 
                                                        class="float-right" 
                                                        @click="toggleEdit" 
                                                        v-show="!editTemplate"
                                                    >
                                                        <font-awesome-icon icon="arrows-alt-v"/> Change signature order
                                                    </CButton>
                                                </template>
                                                <CDataTable
                                                    :items="signatories"
                                                    :fields="signatories_field"
                                                    border
                                                    sorter
                                                    size="sm"
                                                    hover
                                                    pagination
                                                >
                                                    <template #order="{item, index}">
                                                        <td v-if="editTemplate">
                                                            <template v-if="parseInt(item.order) != 1">
                                                                <CInput 
                                                                    onkeyup="if (/\D/g.test(this.value)) this.value = this.value.replace(/\D/g,'')"
                                                                    style="max-width: 50px;"
                                                                    type="text" 
                                                                    maxlength="2"
                                                                    v-model="item.order"
                                                                />
                                                            </template>
                                                            <template v-else>
                                                            {{ item.order }}
                                                            </template>
                                                        </td>
                                                        <td v-if="!editTemplate">
                                                        {{item.order}}
                                                        </td>
                                                    </template>
                                                    <template #required_signature="{item}">
                                                        <td>
                                                            <!-- <center>
                                                                <p-check 
                                                                    disabled
                                                                    class="p-icon p-bigger" 
                                                                    color="success" 
                                                                    off-color="danger"
                                                                    toggle
                                                                    v-model="item.required_signature"
                                                                >
                                                                    
                                                                    <font-awesome-icon class="icon" icon="check" slot="extra"/>
                                                                    <font-awesome-icon class="icon" icon="times" slot="off-extra"/>
                                                                    
                                                                    <label slot="off-label"></label>
                                                                </p-check>
                                                            </center> -->
                                                            <i>{{ item.required_signature ? 'Yes' : 'No' }}</i>
                                                        </td>
                                                    </template>
                                                    <template #requirements="{item, index}">
                                                        <td v-if="item.document_signature_requirements.length > 0">
                                                            <CButton
                                                                :color="Boolean(item._toggled) ? 'danger' : 'info'"
                                                                shape="pill" 
                                                                size="sm"
                                                                @click="toggleDetails(item, index)"
                                                            >
                                                                <font-awesome-icon :icon="Boolean(item._toggled) ? 'eye-slash' : 'eye'"/> {{Boolean(item._toggled) ? 'Hide Requirements' : 'Show Requirements'}}
                                                            </CButton>
                                                        </td>
                                                        <td v-if="item.document_signature_requirements.length <= 0">
                                                            <i>N/a</i>
                                                        </td>
                                                    </template>
                                                    <template #details="{item}">
                                                        <CCollapse :show="Boolean(item._toggled)">
                                                            <CCard border-color="info">
                                                                <CCardHeader><font-awesome-icon icon="paperclip"/> Signature Requirements for {{item.name}}
                                                                </CCardHeader>
                                                                <CCardBody>
                                                                    <CRow  v-for="(child_item) in item.document_signature_requirements" v-bind:key="child_item.requirement">
                                                                        <CCol lg="12">
                                                                            <!-- :value="child_item.id+'_'+item.id -->
    
                                                                            <p-check class="p-icon p-bigger" color="success" v-model="checked_requirements_id" 
                                                                            :value="child_item.id+'_'+item.id+'_'+child_item.requirement+'_'+item.name">
                                                                                <font-awesome-icon class="icon" icon="check"/>
                                                                                {{child_item.requirement}}
                                                                            </p-check>  <br>
                                                                        </CCol>
                                                                    </CRow>
                                                                </CCardBody>
                                                            </CCard>
                                                        </CCollapse>
                                                    </template>
                                                </CDataTable>
                                            </template>
                                        </CCol>
                                    </CRow>
                                </CTab>
                                <CTab :disabled="!selected_user_template || is_getting_requirements">
                                    <template slot="title">
                                        Shared Routing <CBadge color="success" v-if="users_shared_routing_list.length > 0">( {{users_shared_routing_list.length}} )</CBadge>
                                    </template>
                                    <CRow>
                                        <CCol lg="12">
                                            <CDataTable
                                                    :items="users_shared_routing_list"
                                                    :fields="users_shared_routing_list_fields"
                                                    :items-per-page="10"
                                                    border
                                                    size="sm"
                                                    sorter
                                                >
                                                <template #action>
                                                    <td>
                                                        ----
                                                    </td>
                                                </template>
                                            </CDataTable>
                                        </CCol>
                                    </CRow> 
                                </CTab>
                                <CTab :disabled="!selected_user_template || is_getting_requirements" >
                                    <template slot="title">
                                        External Attachments <CBadge color="success" v-if="external_files_list.filter(x => !x.customRow).length > 0">({{external_files_list.filter(x => !x.customRow).length}})</CBadge>
                                    </template>
                                    <div ref="mydiv">
                                        <CRow>
                                            <CCol lg="4">
                                                <input 
                                                    ref="_externalFiles" 
                                                    type="file" 
                                                    multiple
                                                    v-on:change="onFileChange"
                                                    :accept="'image/*,.pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
                                                >
                                                <a-popover placement="left">
                                                    <template slot="title">
                                                        <font-awesome-icon icon="info-circle"/> Information
                                                    </template>
                                                    <template slot="content">
                                                        When pasting a file to an external attachment, click the table to focus on it.
                                                    </template>
                                                    <font-awesome-icon 
                                                        style="color: #0074D9" 
                                                        icon="info-circle" 
                                                        size="lg"/>
                                                </a-popover>
                                            </CCol>
                                            <CCol lg="5"/>
                                            <CCol lg="3">
                                                <!-- <CButton class="float-right" color="info" 
                                                @click="triggerPaste">
                                                    <small><font-awesome-icon 
                                                        style="color: white" 
                                                        icon="copy" 
                                                        size="lg"/>
                                                    Click to paste file from clipboard.</small>
                                                </CButton> -->
                                                <!--  -->
                                                <!-- style="position: absolute; opacity: 0; height: 1px; width: 1px;" -->
                                                <!-- <textarea ref="pasteArea" @paste="handlePaste" ></textarea> -->

                                            </CCol>
                                        </CRow>
                                        
                                        <CRow >
                                            <!-- <CInput id="myInput" v-if="hide_me"></CInput> -->
                                            <CCol lg="12">
                                                <!-- :scopedSlots="{
                                                    row: customRowRenderer
                                                }" -->
                                                <CDataTable
                                                    :key="external_files_key"
                                                    :items="external_files_list"
                                                    :fields="external_files_field"
                                                    border
                                                    size="sm"
                                                    sorter
                                                    hover
                                                >

                                                <template #filetype="{item}"> 
                                                    <template v-if="item.customRow">
                                                        <td colspan="6">
                                                            <CCol lg="12" >
                                                                <CInput placeholder="Paste your file here..." 
                                                                    readonly
                                                                    id="paste_area"
                                                                    style="margin:0"
                                                                >
                                                                </CInput>
                                                            </CCol>
                                                        </td>
                                                    </template>
                                                    <template v-else>
                                                        <td>
                                                            <center>
                                                                <img class="attachment-icon" :src="'/static/img/image-attachment-placeholder.jpg'"  v-if="item.filetype.split('/')[0] == 'image'"/> 
                                                                <img class="attachment-icon" :src="'/static/img/pdf-attachment-placeholder.png'"  v-if="item.filetype == 'application/pdf'"/> 
                                                                <img class="attachment-icon" :src="'/static/img/excell-attachment-placeholder.jpg'"  v-if="item.filetype == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || item.filetype == 'application/vnd.ms-excel'"/> 
                                                            </center>
                                                        </td>
                                                    </template>
                                                </template>
                                                <template #checklist="{item, index}">
                                                    
                                                    <template v-if="item.customRow">
                                                        <!-- <td></td> -->
                                                    </template>
                                                    <template v-else>
                                                        <td>
                                                            <!-- :selectable="(option) => tagged_attachments_id.filter(x => x == option.id).length <= 0" -->
                                                                <CRow>
                                                                <CCol :lg="item.checklist ? '10' : '12'" :style="item.checklist ? 'padding-right: 0 !important;' : ''">
                                                                    <v-select 
                                                                        :clearable="false"
                                                                        label="name"
                                                                        @option:selected="selectChangeChecklistOnListExt(item, index)"
                                                                        :options="list_of_attachments"
                                                                        :class="has_required_attachments && !item.checklist ? 'has-error' : 'has-success'"
                                                                        v-model="item.checklist"
                                                                    />
                                                                </CCol>
                                                                <CCol lg="2" style="padding-left: 2px !important;" v-show="item.checklist">
                                                                    <CButton title="Clear selection." color="dark" class="cstm-btn" @click="clearExternalChecklist(item)">
                                                                        <font-awesome-icon icon="times"/>
                                                                    </CButton>
                                                                </CCol>
                                                                </CRow>
                                                        </td>
                                                    </template>
                                                </template>
                                                <template #filename="{item}"> 
                                                    <template v-if="item.customRow">
                                                        <!-- <td></td> -->
                                                    </template>
                                                    <template v-else>
                                                        <td :title="item.filename">
                                                            {{ item.filename.length > 15 ? item.filename.substring(0, 15) + '...' +  item.filename.split('.')[1] : item.filename}} 
                                                        </td>
                                                    </template>
                                                </template>
                                                <template #filesize="{item}"> 
                                                    <template v-if="item.customRow">
                                                        <!-- <td></td> -->
                                                    </template>
                                                    <template v-else>
                                                        <td :title="item.filesize">
                                                            {{ item.filesize }} 
                                                        </td>
                                                    </template>
                                                </template>
                                                <template #remarks="{item}"> 
                                                    
                                                    <template v-if="item.customRow">
                                                        <!-- <td></td> -->
                                                    </template>
                                                    <template v-else>
                                                        <td>
                                                            <CTextarea
                                                                class="grp-text-area"
                                                                placeholder="Remarks.."
                                                                autocomplete="off"  
                                                                v-tocapitalize
                                                                v-model="item.remarks"
                                                            /> 
                                                        </td>
                                                    </template>
                                                </template>
                                                <template #action="{item, index}"> 
                                                    <template v-if="item.customRow">
                                                        <!-- <td></td> -->
                                                    </template>
                                                    <template v-else>
                                                        <td>
                                                            <center>
                                                                <CButton 
                                                                    block
                                                                    color="danger" 
                                                                    shape="pill" 
                                                                    size="sm"
                                                                    @click="removeExternalAttachment(item, index)"
                                                                >
                                                                    <font-awesome-icon icon="times"/> Remove Attachment
                                                                </CButton>
                                                            </center>
                                                        </td>
                                                    </template>
                                                </template>
                                                <!-- Scoped slot for rows -->
                                                <!-- <template v-slot:row="props"> -->
                                                    <!-- Check if it's a custom row -->
                                                    <!-- <tr v-if="props.item.customRow"> -->
                                                    <!-- This custom row will span all columns -->
                                                    <!-- <td :colspan="2" class="text-center custom-row">
                                                        Custom Content for Row {{ props.index + 1 }} - Spanning all columns
                                                    </td> -->
                                                    <!-- </tr> -->
                                                    <!-- Normal rows will render as usual -->
                                                    <!-- <tr v-else> -->
                                                    <!-- <td>{{ scope.item.name }}</td>
                                                    <td>{{ scope.item.age }}</td>
                                                    <td>{{ scope.item.city }}</td> -->
                                                    <!-- </tr> -->
                                                <!-- </template> -->
                                                    <!-- <template #row="item">
                                                        {{ item }} -->
                                                        <!-- Check if it's a custom row -->
                                                        <!-- <tr v-if="scope.item.customRow">
                                                        <td :colspan="fields.length" class="text-center">
                                                            Custom Content for Row {{ scope.index + 1 }}
                                                        </td>
                                                        </tr> -->
                                                        <!-- Render normal rows -->
                                                        <!-- <tr v-else> -->
                                                        <!-- <td>{{ scope.item.name }}</td>
                                                        <td>{{ scope.item.age }}</td>
                                                        <td>{{ scope.item.city }}</td> -->
                                                        <!-- </tr> -->
                                                    <!-- </template> -->
                                                </CDataTable>
                                            </CCol>
                                        </CRow>
                                    </div>
                                </CTab>
                                <CTab :disabled="!selected_user_template || is_getting_requirements">
                                    <template slot="title">
                                        Internal Attachments <CBadge color="success" v-if="internal_files_v2.length > 0">( {{internal_files_v2.filter(x => x.attachment_id).length }} )</CBadge>
                                    </template>
                                    <CRow>
                                        <CCol lg="3">
                                            <label>Select Document Category</label>
                                            <v-select 
                                                label="category"
                                                :options="templates_lists"
                                                v-model="selected_template"
                                                :class="!selected_template ? 'has-error' : 'has-success'"
                                            />
                                        </CCol>
                                        <CCol lg="4">
                                            <CInput 
                                                readonly
                                                label="Select reference number" 
                                                v-model="selected_reference"
                                            >
                                                        
                                                <template #append  >
                                                    <CButton color="primary"
                                                    @click="inputSearch(selected_template_reference_number)">
                                                        <font-awesome-icon icon="search"/>
                                                    </CButton>
                                                </template>
                                            </CInput>
                                        </CCol>
                                        <!-- <CCol lg="2">
                                            :selectable="(option) => tagged_attachments_id.filter(x => x == option.id).length <= 0"
                                            <label>Attachment</label>
                                            <v-select 
                                                :disabled="!selected_template"
                                                label="name"
                                                :options="list_of_attachments"
                                                v-model="selected_attachment"
                                                :class="has_required_attachments && !selected_attachment ? 'has-error' : 'has-success'"
                                            />
                                        </CCol> -->
                                        <CCol lg="3">
                                            <CTextarea
                                                :disabled="!selected_template"
                                                class="grp-text-area-cust"
                                                placeholder="Please add remarks *"
                                                autocomplete="off"  
                                                label="Enter remarks"
                                                v-tocapitalize
                                                v-model="selected_reference_remarks"
                                            />
                                        </CCol>
                                        <CCol lg="2">
                                            
                                            <!-- <CButton 
                                                :disabled="!selected_template"
                                                color="primary"
                                                @click="addInternalFile()"
                                                style="margin-top:28px;"
                                            >
                                                <font-awesome-icon icon="plus"/> Add Attachment
                                            </CButton> -->
                                            <CDropdown
                                            
                                                :disabled="!selected_template || !selected_reference || is_getting_file_to_add_internally"
                                                style="margin-top:28px;"
                                                color="info"
                                                :togglerText="is_getting_file_to_add_internally ? 'Getting...' : 'Get File(s)'" 
                                            >
                                                <CDropdownItem @click="addInternalFile('main_file')">Main File</CDropdownItem>
                                                <CDropdownItem @click="addInternalFile('all_file')">All File(s) - Attachments included</CDropdownItem>
                                            </CDropdown>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol lg="9"/>
                                        <CCol lg="3">
                                            <!-- <a-popover 
                                                title="Attachments Checklist:" 
                                                placement="left" 
                                                v-if="selected_user_template && selected_user_template.document_template_attachment_checklist.length > 0"
                                            >
                                                <template slot="content">
                                                    <CListGroup>
                                                        <CListGroupItem v-for="(item, index) in selected_user_template.document_template_attachment_checklist" v-bind:key="index">
                                                        {{index+1}}. <strong>{{item.name}}</strong>
                                                        </CListGroupItem>
                                                    </CListGroup>
                                                </template>
                                                <div style="margin-top: 10px;
                                                            margin-bottom: 10px;
                                                            border-radius: 5px; 
                                                            padding: 5px;
                                                            background-color:#03a9f4;
                                                            color: white; 
                                                            text-align: center;">
                                                    <font-awesome-icon 
                                                        v-if="selected_user_template && selected_user_template.document_template_attachment_checklist.length > 0"
                                                        style="color: #4caf50;" 
                                                        icon="tasks" 
                                                        size="lg"
                                                    /> Attachments Checklist
                                                </div>
                                            </a-popover> -->
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol lg="12">
                                            <CDataTable
                                                :key="internal_files_key"
                                                :items="internal_files_v2"
                                                :fields="internal_files_fields_v2"
                                                border
                                                hover
                                                @row-clicked="parentRowClickedInternal"
                                                size="sm"
                                                sorter
                                            >
                                            
                                                
                                                <template #filetype="{item}">
                                                    <template v-if="!item.hidden">
                                                        <td>
                                                            <center>
                                                                <a :href="item.url" target="_blank">
                                                                    <img class="attachment-icon" :src="'/static/img/image-attachment-placeholder.jpg'"  v-if="item.filetype.split('/')[0] == 'image'"/> 
                                                                    <img class="attachment-icon" :src="'/static/img/pdf-attachment-placeholder.png'"  v-if="item.filetype == 'application/pdf'"/> 
                                                                    <img class="attachment-icon" :src="'/static/img/excell-attachment-placeholder.jpg'"  v-if="item.filetype == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || item.filetype == 'application/vnd.ms-excel'"/> 
                                                                </a>
                                                            </center>
                                                        </td>
                                                    </template>
                                                    <template v-else></template>
                                                </template>
                                                <template #filename="{item}">
                                                    <template v-if="!item.hidden">
                                                        <td v-if="item.parent">
                                                            <strong>{{item.reference_number}}</strong>
                                                        </td>
                                                        <td v-else-if="item.main" :title="item.filename+'.'+item.fileext">
                                                            <small><i>Main File</i></small><br>
                                                            {{ item.filename.length > 30 ? item.filename.substring(0, 30) + '...' : item.filename}}{{'.'+item.fileext}}
                                                        </td>
                                                        <td v-else :title="item.filename+'.'+item.fileext">
                                                            <small><i>Supporting File(s)</i></small><br>
                                                            {{ item.filename.length > 30 ? item.filename.substring(0, 30) + '...' : item.filename}}{{'.'+item.fileext}}
                                                        </td>
                                                    </template>
                                                    <template v-else>

                                                    </template>
                                                </template>
                                                
                                                <template #filesize="{item}"> 
                                                    <template v-if="!item.hidden">
                                                        <td> 
                                                            {{ item.filesize }}
                                                        </td>
                                                    </template> 
                                                    <template v-else>
                                                    </template>
                                                </template>
                                                <template #remarks="{item}"> 
                                                    <template v-if="!item.hidden">
                                                        <td v-if="!item.parent"> 
                                                            <CTextarea
                                                                class="grp-text-area"
                                                                placeholder="Remarks.."
                                                                autocomplete="off"  
                                                                v-tocapitalize
                                                                v-model="item.remarks"
                                                            /> 
                                                        </td>
                                                        <td v-else></td>
                                                    </template>
                                                    <template v-else>

                                                    </template>
                                                </template>
                                                <template #checklist="{item, index}">
                                                    <template v-if="!item.hidden">
                                                        <td v-if="!item.parent">
                                                            <CRow>
                                                                <CCol :lg="item.checklist ? '10' : '12'" :style="item.checklist ? 'padding-right: 0 !important;' : ''">
                                                                    <v-select 
                                                                        :clearable="false"
                                                                        label="name"
                                                                        @option:selected="selectChangeChecklistOnListInt(item, index)"
                                                                        :options="list_of_attachments"
                                                                        :class="has_required_attachments && !item.checklist ? 'has-error' : 'has-success'"
                                                                        v-model="item.checklist"
                                                                    />
                                                                </CCol>
                                                                <CCol lg="2" style="padding-left: 2px !important;" v-show="item.checklist">
                                                                    <CButton title="Clear selection." color="dark" class="cstm-btn" @click="clearInternalChecklist(item)">
                                                                        <font-awesome-icon icon="times"/>
                                                                    </CButton>
                                                                </CCol>
                                                             </CRow>
                                                        </td>
                                                        <td v-else></td>
                                                    </template>
                                                    <template v-else>

                                                    </template>
                                                </template>
                                                <template #action="{item, index}">
                                                    <template v-if="item.parent">
                                                        <td>
                                                            <center>
                                                                <CButton 
                                                                    color="danger" 
                                                                    shape="pill" 
                                                                    title="Remove Attachment"
                                                                    size="sm"
                                                                    @click="removeInternalFileV2('parent_file', item, index)"
                                                                >
                                                                    Remove Attachment
                                                                    <!-- <font-awesome-icon icon="times"/>  -->
                                                                </CButton>
                                                            </center>
                                                        </td>
                                                    </template>
                                                    <template v-else-if="item.main">
                                                        <template v-if="!item.hidden">
                                                            <td>
                                                                <center>
                                                                    <CButton 
                                                                        color="warning" 
                                                                        shape="pill" 
                                                                        title="Remove Attachment"
                                                                        @click="removeInternalFileV2('main_file', item, index)"
                                                                        size="sm"
                                                                    >
                                                                        <font-awesome-icon icon="times"/> 
                                                                    </CButton>
                                                                </center>
                                                            </td>
                                                        </template>
                                                        <template v-else>

                                                        </template>
                                                    </template>
                                                    <template v-else>
                                                        <template v-if="!item.hidden">
                                                            <td>
                                                                <center>
                                                                    <CButton 
                                                                        color="warning" 
                                                                        shape="pill" 
                                                                        title="Remove Attachment"
                                                                        @click="removeInternalFileV2('supporting_file', item, index)"
                                                                        size="sm"
                                                                    >
                                                                        <font-awesome-icon icon="times"/> 
                                                                    </CButton>
                                                                </center>
                                                            </td>
                                                        </template>
                                                        <template v-else>

                                                        </template>
                                                    </template>
                                                </template>
                                            </CDataTable>
                                            
                                            <!-- <CDataTable
                                                :key="internal_files_key"
                                                :items="internal_files"
                                                :fields="internal_files_fields"
                                                border
                                                size="sm"
                                                sorter
                                                pagination
                                            >
                                            
                                            <template #_refNum="{item}">
                                                <td :title="item._refNum">
                                                    {{item._refNum.length > 15 ? item._refNum.substring(0, 15) + '...' : item._refNum }}
                                                </td>
                                            </template>
                                            
                                            <template #_remakrs="{item}">
                                                <td>
                                                    <CTextarea
                                                        class="grp-text-area"
                                                        placeholder="Remarks.."
                                                        autocomplete="off"  
                                                        v-tocapitalize
                                                        v-model="item._remakrs"
                                                    /> 
                                                </td>
                                            </template>
                                            <template #_checklist="{item, index}">
                                                <td>
                                                    <v-select 
                                                        label="name"
                                                        @option:selected="selectChangeChecklistOnList(item, index)"
                                                        :options="list_of_attachments"
                                                        v-model="item._checklist"
                                                        :class="has_required_attachments && !item._checklist ? 'has-error' : 'has-success'"
                                                        :selectable="(option) => tagged_attachments_id.filter(x => x == option.id).length <= 0"
                                                    />
                                                </td>

                                            </template>

                                            <template #action="{item, index}">
                                                <td v-if="item.attachments.length > 0">
                                                    <center>
                                                        <CButton
                                                            block
                                                            :color="Boolean(item._toggled) ? 'danger' : 'info'"
                                                            shape="pill" 
                                                            size="sm"
                                                            @click="toggleInternalAttachment(item, index)"
                                                        >
                                                            <font-awesome-icon :icon="Boolean(item._toggled) ? 'eye-slash' : 'eye'"/> {{Boolean(item._toggled) ? 'Hide File(s)' : 'Show File(s)'}}
                                                        </CButton>
                                                        <CButton
                                                            block
                                                            v-if="!item._toggled"
                                                            color="danger"
                                                            shape="pill" 
                                                            
                                                            size="sm"
                                                            @click="removeInternalAttachment(item, index)"
                                                        >
                                                            <font-awesome-icon icon="times"/> Remove
                                                        </CButton>
                                                    </center>
                                                </td>
                                            </template>
                                            <template #details="{item}">
                                                <CCollapse :show="Boolean(item._toggled)">
                                                    <CCard border-color="info">
                                                        <CCardHeader><font-awesome-icon icon="paperclip"/> Internal Attachment of {{item._refNum}}
                                                        </CCardHeader>
                                                        <CCardBody>
                                                            <CRow>
                                                                <CCol lg="12">
                                                                    <table class="table" style="width:100%">
                                                                        <thead>
                                                                            <tr>
                                                                                <th></th>
                                                                                <th>File Name</th>
                                                                                <th></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr v-for="(child_item, index) in item.attachments" v-bind:key="index">
                                                                                <td>
                                                                                    <center>
                                                                                        <p-check 
                                                                                            :title="child_item.name + '.' + child_item.file.split('.')[1]"
                                                                                            class="p-icon p-bigger" 
                                                                                            checked
                                                                                            color="success" 
                                                                                            v-model="checked_attachments" 
                                                                                            :value="child_item.attachment_id+'@spacer@'+child_item.file+'@spacer@'+child_item.name+'@spacer@'+item._refNum"
                                                                                        >
                                                                                            <font-awesome-icon class="icon" icon="check"/>
                                                                                        </p-check>
                                                                                    </center>
                                                                                </td>
                                                                                <td :title="child_item.name + '.' + child_item.file.split('.')[1]">
                                                                                    {{child_item.name.length > 50 ? child_item.name.substring(0, 50) + '...' +  child_item.file.split('.')[1] : child_item.name + '.' + child_item.file.split('.')[1]}}
                                                                                </td>
                                                                                <td>
                                                                                    <center>
                                                                                        <a :href="child_item.url" target="_blank">
                                                                                            <img class="attachment-icon" :src="'/static/img/image-attachment-placeholder.jpg'"  v-if="child_item.file_mime.split('/')[0] == 'image'"/> 
                                                                                            <img class="attachment-icon" :src="'/static/img/pdf-attachment-placeholder.png'"  v-if="child_item.file_mime == 'application/pdf'"/> 
                                                                                            <img class="attachment-icon" :src="'/static/img/excell-attachment-placeholder.jpg'"  v-if="child_item.file_mime == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || item.filetype == 'application/vnd.ms-excel'"/> 
                                                                                        </a>
                                                                                    </center>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </CCol>
                                                            </CRow>
                                                        </CCardBody>
                                                    </CCard>
                                                </CCollapse>
                                            </template>
                                            </CDataTable> -->
                                        </CCol>
                                    </CRow>
                                </CTab>
                                <CTab :disabled="!selected_user_template || is_getting_requirements">
                                    <template slot="title">
                                        Remarks <CBadge color="success" v-if="signatory_remarks.length > 0">( {{signatory_remarks.length}} )</CBadge>
                                    </template>
                                    <CRow>
                                        <CCol lg="12">
                                            <CButton
                                                v-if="!template_file_remarks_added"
                                                color="success"
                                                @click="addSignatoryRemarks()"
                                            >
                                                <font-awesome-icon icon="comment-alt"/> Add Remarks
                                            </CButton>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol lg="12">
                                            <CDataTable
                                                    :key="signatory_remarks_key"
                                                    :items="signatory_remarks"
                                                    :fields="signatory_remarks_field"
                                                    :items-per-page="10"
                                                    border
                                                    size="sm"
                                                    sorter
                                                >
                                                <template #action="{index}">
                                                    <td>
                                                        <CButton 
                                                            block
                                                            color="danger" 
                                                            shape="pill" 
                                                            size="sm"
                                                            @click="signatory_remarks.splice(index, 1), template_file_remarks_added = false"
                                                        >
                                                            <font-awesome-icon icon="times"/> Remove My Remarks
                                                        </CButton>
                                                    
                                                    </td>
                                                    
                                                </template>
                                                <template #remarks="{item}"> 
                                                    <td>
                                                        <CTextarea
                                                            class="grp-text-area"
                                                            placeholder="Content.."
                                                            autocomplete="off"  
                                                            v-model="item.remarks"
                                                        />
                                                    </td>
                                                </template>
                                            </CDataTable>
                                        </CCol>
                                    </CRow>
                                </CTab>
                            </CTabs>
                        </CCol>
                    </CRow>
                </CCardBody>
            </CCard>
        </CCol>
        <!-- <CModal
            v-if="using_new_template_version"
            :show.sync="show_signatory_selection_modal"
            size="xl"
            color="primary"
            :closeOnBackdrop="false"
        >
            <template #header>
                <h5> <font-awesome-icon icon="signature"/> Signatory Selection </h5>
                <CButton 
                    class="pull-right" 
                    color="light" 
                    shape="pill" 
                    size="sm"
                    @click="closeSignatorySelectionModal()"
                >
                    <font-awesome-icon icon="times"/>
                </CButton>
            </template>
            
            <template #body-wrapper>
                
                <CCardBody v-if="new_version_signatories.length > 0">
                    <CRow>
                        <CCol lg="12">
                            <CTabs justified variant="pills" :active-tab="current_tab_signatory_selection" @update:activeTab="changeTabSignatorySelection">
                                <CTab v-for="(matrix, matrix_index) in new_version_signatories" v-bind:key="matrix_index">
                                    <template slot="title">
                                        {{ matrix.purpose }} <CBadge color="success">( {{ selected_signatory_nv_counter[matrix_index].selected }} )</CBadge>
                                    </template>
                                </CTab>
                            </CTabs>
                        </CCol>
                    </CRow>
                    <br>
                    
                    <CRow>
                        <CCol lg="8"/>
                        <CCol lg="4">
                            <CInput 
                                type="text" 
                                placeholder="Search Signatory..."
                                v-model="filtering_signatory[current_tab_signatory_selection]"
                            >
                            <template #prepend-content>
                                <CIcon name="cil-search"/>
                            </template>
                            </CInput>
                        </CCol>
                    </CRow>
                    <CRow v-if="new_version_signatories.length > 0">
                        <CCol lg="12">
                            <CListGroup>
                                <CListGroupItem v-for="(signatory, signatory_index) in filtering_signatory[current_tab_signatory_selection] ? new_version_signatory_computed : new_version_signatories[current_tab_signatory_selection].template_signatory" v-bind:key="signatory_index">
                                    <CRow>
                                        <CCol lg="12">
                                            <h5> {{signatory.order}}. {{signatory?.user?.first_name}} {{ signatory?.user?.last_name }}</h5>
                                        </CCol>
                                    </CRow>
                                    <br>
                                    <CRow>
                                        <template v-if="signatory.document_signature_requirements.length > 0">
                                            <CCol lg="4" v-for="(reqs, reqs_index) in signatory?.document_signature_requirements" v-bind:key="reqs_index">
                                                <p-check 
                                                    :value="reqs.id+'@param@'+signatory.id+'@param@'+reqs.requirement+'@param@'+signatory?.user?.first_name+' '+ signatory?.user?.last_name"
                                                    class="p-icon p-bigger" 
                                                    color="success" 
                                                    v-model="checked_requirements_id_new_version">
                                                    <font-awesome-icon class="icon" icon="check"/>
                                                    {{ reqs.requirement }}
                                                </p-check>
                                            </CCol>
                                        </template>
                                        <template v-else>
                                        </template>
                                    </CRow>
                                </CListGroupItem>
                            </CListGroup>
                        </CCol>
                    </CRow>
                </CCardBody>
            </template>
            <template #footer>
                <CButton color="secondary" @click="closeSignatorySelectionModal()">
                    <font-awesome-icon class="icon" icon="times"/> Cancel
                </CButton>
                <CButton :color="'primary'" @click="confirmSignatorySelection()">
                    <font-awesome-icon 
                        class="icon" :icon="'save'"
                        /> 
                        Confirm Signatory Selection
                </CButton>

            </template>
        </CModal> -->
        <CModal
            :show.sync="large_list_modal"
            color="primary"
            size="lg"
            >
            <template #header>
                <h6> <font-awesome-icon icon="list"/> Select Reference Number </h6>
                <CButton 
                    class="pull-right" 
                    color="light" 
                    shape="pill" 
                    size="sm"
                    @click="large_list_modal = false"
                >
                    <font-awesome-icon  size="sm" icon="times"/>
                </CButton>
            </template>
            <template #footer>
                <hr>
            </template>
            <template #body-wrapper>
                <CCardBody>
                    <CRow>
                        <CCol lg="6">
                            <CInput 
                                type="text" 
                                placeholder="Search" 
                                autocomplete="off" 
                                v-model="large_list_search"
                            />
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol lg="12">
                            <CDataTable
                                :key="large_list_modal_key"
                                :items="large_list_items"
                                :fields="large_list_field"
                                border
                                sorter
                                @row-clicked="largeListSelectedItem"
                                pagination
                            >
                                <template #action="{item, index}">
                                    <td>
                                        <center>
                                            <CButton 
                                                color="danger" 
                                                @click="removeSignatoryRequirement(item, index)" 
                                                shape="pill" 
                                                size="sm"
                                            >
                                                <font-awesome-icon icon="times"/> Remove
                                            </CButton>
                                        </center>
                                    </td>
                                </template>
                            </CDataTable>
                        </CCol>
                    </CRow>
                </CCardBody>
            </template>
        </CModal>
        <UploadFileViaUrlModal ref="_uploadFileViaURLModal" @documentFoundViaURLConfirmed="confirmedDocument($event)"/>
    </div>
</template>
<script>
import { required,minLength } from "vuelidate/lib/validators"
import UploadFileViaUrlModal from '../../modals/UploadFileViaURLModal'
import DocumentRoutingJS from './js/document-routing';
export default {
    name: 'DocumentRoutingsCreate',
    components: {
        UploadFileViaUrlModal,
    },
    data() {
        return {    
            attachment_checklist_collapsed: false,
            fayl: [],
            hide_me: true,
            current_tab_signatory_selection: 0,
            using_new_template_version: false,
            show_signatory_selection_modal: false,
            is_getting_signatories: false,
            new_version_signatories: [],

            checked_requirements_id_new_version:[],
            filtering_signatory: [],
            
            users_shared_routing_list: [],
            users_shared_routing_list_fields: [
                { key: 'name', label: 'Routing shared to'},
                { key: 'added_by'},
                { key: 'action', _classes: 'th-fixed-width' },
            ],
            
            internal_files_key:0,
            external_files_key:0,
            active_tab: 0,
            list_of_attachments: [],
            tagged_attachments_id: [],

            attachments_display_field: [
                {key: 'name'},
                // {key: 'remarks'},
                {key: 'status', label: 'Tag Status'},
            ],

            
            internal_files_fields_v2: [
                // {key: '', label: ''},
                {key: 'filetype', label: '', _classes: 'td-fxd-wdth'},
                {key: 'filename'},
                {key: 'filesize'},
                {key: 'remarks'},
                {key: 'checklist', label: 'Attachment' },
                // {key: 'uploaded_by'},
                // {key: 'date_uploaded'},
                {key: 'action', _classes: 'td-actn-fxd-wdth'},
            ],

            internal_files_v2: [
                // {parent: true, reference_number: '1233', filetype: '', filename: '', filesize: '', remarks: "", checklist: '', _classes: 'parent-row'},
                // {parent: false, reference_number: '1233', filetype: 'pdf', filename: 'Genesis1', filesize: '101kb', remarks: "Haha bobo1", checklist: 'PO Invoice',},
                // {parent: false, reference_number: '1233', filetype: 'image', filename: 'Genesis2', filesize: '102kb', remarks: "Haha bobo2", checklist: 'PO Invoice',},
                // {parent: false, reference_number: '1233', filetype: 'xlxs', filename: 'Genesis3', filesize: '103kb', remarks: "Haha bobo3", checklist: 'PO Invoice',},
                
                // {parent: true, reference_number: '1234', filetype: '', filename: '', filesize: '', remarks: "", checklist: '', _classes: 'parent-row'},
                // {parent: false, reference_number: '1234', filetype: 'pdf', filename: 'Genesis4', filesize: '104kb', remarks: "Haha bobo4", checklist: 'PO Invoice',},
                // {parent: false, reference_number: '1234', filetype: 'image', filename: 'Genesis5', filesize: '105kb', remarks: "Haha bobo5", checklist: 'PO Invoice',},
                // {parent: false, reference_number: '1234', filetype: 'pdf', filename: 'Genesis6', filesize: '106kb', remarks: "Haha bobo6", checklist: 'PO Invoice',},
            ],


            is_getting_file_to_add_internally: false,


            external_files_field: [
                {key: 'filetype', label: ''},
                {key: 'filename'},
                {key: 'filesize'},
                {key: 'remarks'},
                {key: 'checklist', label: 'Attachment' },
                // {key: 'uploaded_by'},
                // {key: 'date_uploaded'},
                {key: 'action', _classes: 'th-fixed-width'},
            ],

            signatory_remarks_key:0,
            signatory_remarks: [],
            signatory_remarks_field: [
                { key: 'remarks'},
                { key: 'remarks_by'},
                // { key: 'date'},
                { key: 'action', _classes: 'th-fixed-width' },
            ],

            external_files_list: [
                
                // {file: null, remarks: null, uploaded_by: null},
                // {file: null, filename: null, remarks: 'lorem ipsum', uploaded_by: 3, date_uploaded: 'today'},
                // {file: null, filename: null, remarks: 'lorem ipsum', uploaded_by: 3, date_uploaded: 'today'},
                // {file: null, filename: null, remarks: 'lorem ipsum', uploaded_by: 3, date_uploaded: 'today'},
            ],

            large_list_search: '',
            large_list_modal: false,
            large_list_modal_key: 0,
            
            large_list: [],
            large_list_field: [
                { key: 'id'},
                { key: 'reference_number' },
            ],


            reference_from_form_data: false,

            show_attachment_list: false,

            attachment_list:[],

            signatory_locked: false,

            can_upload_file_via_url: false,
            main_file_via_url:false,
            main_file_via_form_data: false,


            user_shared: {
                name: null,
                user_id: null,
            },

            // users_shared_routing_list: [],
            // users_shared_routing_list_fields: [
            //     { key: 'name',},
            //     { key: 'action' },
            // ],

            template_file_remarks_added: false,
            template_file_remarks:[],

            template_file: [],
            template_file_via_form_data:null,

            external_files:[],
            external_file_remarks:[],
            additional_slot:1,
            external_file_max_file:20,

            editTemplate: false,
            checked_requirements_id: [],
            labelTxt: {
                labelOn: 'yes',
                labelOff: 'no'
            },


            selected_user_template: null,

            user_templates_lists: [],
            user_template_lists_backup: [],
            
            
            templates_lists: [],

            signatories: [],
            signatories_field: [
                {key: 'name'},
                {key: 'purpose'},
                {key: 'order',},
                {key: 'required_signature', label: 'Signature'},
                {key: 'requirements'},
            ],

            new_version_signatories_v2:[
                // {parent: true,  purpose: 'Creator',     name: '',                       order: '',          required_signature: '',         requirements: "", _classes: `parent-row ${this.$store.getters.getDarkMode ? 'parent-row-dark' : 'parent-row-light'}`},
                // {parent: false, purpose: 'Creator',     name: 'Genesis Ivan Aquino',    order: '1',         required_signature: '1',        requirements: [], hidden: false,},
                
                // {parent: true,  purpose: 'Verifier',    name: '',                       order: '',          required_signature: '',         requirements: "", _classes: `parent-row ${this.$store.getters.getDarkMode ? 'parent-row-dark' : 'parent-row-light'}`},
                // {parent: false, purpose: 'Verifier',    name: 'Gary Mercado',           order: '2',         required_signature: '1',        requirements: [], hidden: true,},
                // {parent: false, purpose: 'Verifier',    name: 'Allan Laguilles',        order: '3',         required_signature: '1',        requirements: [{id: 1, requirement: "DELL"}, {id: 2, requirement: "ACER"}], hidden: true,},
                // {parent: false, purpose: 'Verifier',    name: 'Erick Buenaventura',     order: '4',         required_signature: '1',        requirements: [{id: 3, requirement: "LENOVO"}, {id: 4, requirement: "HP"}], hidden: true,},

                // {parent: true,  purpose: 'Approver',    name: '',                       order: '',          required_signature: '',         requirements: "", _classes: `parent-row ${this.$store.getters.getDarkMode ? 'parent-row-dark' : 'parent-row-light'}`},
                // {parent: false, purpose: 'Approver',    name: 'Lenard Labuntog',        order: '5',         required_signature: '1',        requirements: [], hidden: false,},
                // {parent: false, purpose: 'Approver',    name: 'Carlito Gatchalian',     order: '6',         required_signature: '1',        requirements: [{id: 5, requirement: "SB"}, {id: 6, requirement: "APPLE"}], hidden: false,},
                // {parent: false, purpose: 'Approver',    name: 'Reign Albana',           order: '7',         required_signature: '1',        requirements: [{id: 7, requirement: "TEKTITE"}, {id: 8, requirement: "HKDS"}], hidden: false,},
            ],
            new_version_signatories_field: [
                
                // {key: 'purpose', label: '', sorter: false},
                {key: 'name', label: 'Signatories', sorter: false},
                {key: 'order', _classes: '', sorter: false},
                // {key: 'required_signature', label: 'Signature'},
                // {key: 'requirements'},

            ],


            internal_files: [],
            internal_files_fields: [
                {key: '_refNum', label: 'Reference Number'},
                {key: '_remakrs', label: 'Remarks'},
                {key: '_checklist', label: 'Attachment' },
                {key: 'action', _classes: 'th-fixed-width'},
            ],

            routing: {
                document_template_id: null,
                dtcs_id: null,
                reference_number: null,
                lines: [],
                description: null
            },
            attachments:null,
            

            selected_template: null,
            selected_template_reference_number: [],

            checked_attachments: [],
            
            selected_reference: null,
            selected_id: null,
            selected_reference_remarks: '',
            selected_attachment: '',

            routing_suggestion: {
                attachment_id: null,
                from_template_id: null,
                to_template_id: null,
                reference_number: null
            },
            is_getting_requirements: false,
            selectedAttachmentFile: null,
            is_start_routing_confirm_button:true,
            mergedAttachments: [],
            fold_signatories: false,
            // warnings:[]
        }
    },
    computed: {
        isValid () { return !this.$v.routing.$invalid },
        isDirty () { return this.$v.routing.$anyDirty },
        large_list_items: function () {
            return this.large_list.filter((e) => e.reference_number.toLowerCase().indexOf(this.large_list_search.toLowerCase()) > -1);
        },
        attachment_display_data: function () {
        //    let data = this.selected_user_template.document_template_category.document_template_category_attachment_checklist
           let data = this.list_of_attachments
        //    let data = []
           return data.map(item => {
                const match = this.tagged_attachments_id.find(obj => obj === item.id);
                return match ? { ...item, status: 'used' } : { ...item, status: 'unused'} ;
            });
        },
        has_required_attachments: function () {
            if(this.selected_user_template) {
                // let data = this.selected_user_template.document_template_category.document_template_category_attachment_checklist
                let data = this.list_of_attachments
                // let data = []
                if(data.filter(x => parseInt(x.required) == 1).length > 0) {
                    return true;
                } 
                return false;
            }
        },
        all_signatory_has_requirements: function (){
            if(this.selected_user_template) {
                let x = this.selected_user_template.document_template_signatures.filter(c => c.document_signature_requirements.length > 0);
                if(x.length == this.selected_user_template.document_template_signatures.length) {
                    return true;
                } return false;
            }
        },
        all_signatory_has_requirements_new_version: function (){
            let x = this.new_version_signatories_v2.filter(c => !c.parent && c.document_signature_requirements.length > 0);
            if(x.length == this.new_version_signatories_v2.filter(d => !d.parent).length) {
                return true;
            } return false;
        },
        warnings_for_saving_as_draft: function () {
            let list = []

            if(!this.routing.reference_number) {
                list.push("Reference number cannot be empty.")
            }

            if(this.template_file.length <= 0) {
                list.push("Main file cannot be empty.")
            }

            if(!this.selected_user_template) {
                list.push("Please select signatory template from the list.")
            }

            if(!this.using_new_template_version) {
                if(this.signatories.length > 0) {
                    if(this.all_signatory_has_requirements && this.checked_requirements_id.length <= 0) {
                        list.push("No default signatory found. Please select in the list.")
                    }
                }
            } else {
                if(this.new_version_signatories_v2.length > 0)  {
                    if(this.all_signatory_has_requirements_new_version && this.checked_requirements_id_new_version.length <= 0) {
                        list.push("No default signatory found. Please select in the list.")
                    }
                }
            }
            
            return list;
        },
        warnings_for_starting_routing: function () {
            let start_routing_list = []
            start_routing_list = JSON.parse(JSON.stringify(this.warnings_for_saving_as_draft));

            // if (this.attachment_list) {
                // this.$nextTick(() => {
            let required_attachment_id = this.list_of_attachments.filter(x => parseInt(x.required) == 1).map(x => x.id);
                    // let required_attachment_id = []
            let tagged_attachments_id = [...new Set(this.tagged_attachments_id)]
            if(!required_attachment_id.every(value => tagged_attachments_id.includes(value))) {
                start_routing_list.push("Some of the required attachment have not been tagged.")
            } 
                // })
            // }
            return start_routing_list;
        },
        warnings: function () {
            return this.warnings_for_starting_routing
        },
        selected_requirement_id_of_signatory: function () {
            let checked_requirements_id_new_version_counter=[];
            for (let j = 0; j < this.checked_requirements_id_new_version.length; j++) {
                const element = this.checked_requirements_id_new_version[j];
                const x = element.split("@param@");
                checked_requirements_id_new_version_counter.push(parseInt(x[0]))
                // x[0] requirements ID
                // x[1] document template signatory ID
                // x[2] requirement name
                // x[3] document template signatory user name
            }
            return checked_requirements_id_new_version_counter;

        },
        selected_signatory_nv_counter: function () {
            let main_counter = this.new_version_signatories_v2.filter(x => x.document_signature_requirements.length == 0 && !x.parent).length
            const selected_reqs_counter = [...new Map(this.checked_requirements_id_new_version.map(item => {
                const [index0, index1] = item.split('@param@');
                return [index1, index0];
            })).values()]
            return main_counter + selected_reqs_counter.length;
        },
        new_version_signatory_computed: function () {
            let list = this.new_version_signatories[this.current_tab_signatory_selection].template_signatory.filter(
                x => x.user.name.toLowerCase().includes(this.filtering_signatory[this.current_tab_signatory_selection].toLowerCase())
            );
            return list;
        },
        old_signatories_counter: function () {
            let main_counter = this.signatories.filter(x => x.document_signature_requirements.length == 0 && !x.parent).length
            const selected_reqs_counter = [...new Map(this.checked_requirements_id.map(item => {
                const [index0, index1] = item.split('_');
                return [index1, index0];
            })).values()]
            return main_counter + selected_reqs_counter.length;
        }
    },
    validations: {
        routing: {
            document_template_id: { required},
            reference_number: { required, minLength: minLength(5)  },
            // description: { required, minLength: minLength(5)  },
        },
    },
    created() {
        this.$v.$touch()
        this.getUserTemplates();
        this.getTemplates();
        this.$emit('activeTab', 1);
        this.checkIfStateHasBase64PDF();
        this.routing_suggestion.attachment_id = this.$route.query.ref_1 ? this.$route.query.ref_1 : null;
        this.routing_suggestion.from_template_id = this.$route.query.ref_2 ? this.$route.query.ref_2 : null;
        this.routing_suggestion.to_template_id = this.$route.query.ref_3 ? this.$route.query.ref_3 : null;
        this.routing_suggestion.reference_number = this.$route.query.ref_4 ? this.$route.query.ref_4 : null;
        if(this.$route.query.ref_4) {
            this.addInternalFile('all_file');
        }
        

    },
    mounted() {
        window.vm = this;
        const c_table = this.$refs.mydiv;
        c_table.addEventListener('paste', e => {
            if(this.active_tab == 2) {
                const files = e.clipboardData.files;
                if(files.length > 0) {
                    for (let index = 0; index < files.length; index++) {
                        const element = files[index];
                        if(element.type.split('/')[0] != 'image' 
                            && element.type != 'application/pdf' 
                                && element.type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
                                    && element.type != 'application/vnd.ms-excel') 
                        {
                            this.$swal({
                                customClass: 'ta-left',
                                toast: true,
                                position: 'top-right',
                                timer: 5000,
                                html: `<div class="swal2-header">
                                        <ul class="swal2-progress-steps" style="display: none;"></ul>
                                        <div class="swal2-icon swal2-error swal2-icon-show" style="display: flex;">
                                            <span class="swal2-x-mark">
                                                <span class="swal2-x-mark-line-left"></span>
                                                <span class="swal2-x-mark-line-right"></span>
                                            </span>
                                        </div>
                                        <h5>Files Invalid. <br>Only accept pdf, images, and the latest excel file.</h5>
                                    </div>`,
                                showConfirmButton: false,
                                timerProgressBar: true,
                            })
                            continue;
                        }


                        if (element.size / Math.pow(1024,1) > 8000) {
                            this.$swal({
                                customClass: 'ta-left',
                                toast: true,
                                position: 'top-right',
                                timer: 5000,
                                html: `<div class="swal2-header">
                                        <ul class="swal2-progress-steps" style="display: none;"></ul>
                                        <div class="swal2-icon swal2-error swal2-icon-show" style="display: flex;">
                                            <span class="swal2-x-mark">
                                                <span class="swal2-x-mark-line-left"></span>
                                                <span class="swal2-x-mark-line-right"></span>
                                            </span>
                                        </div>
                                        <h5>${element.name.length > 10 ? element.name.substring(0, 10) + '...' +  element.name.split('.')[1] : element.name} is  too large. Maximum size per file is 8MB.</h5>
                                    </div>`,
                                showConfirmButton: false,
                                timerProgressBar: true,
                            })
                            continue;
                        } 

                        this.external_files_list.push(
                            {
                                customRow: false,
                                file: element,
                                filetype: element.type,
                                filename: element.name,
                                filesize: this.formatBytes(element.size),
                                remarks: '',
                                checklist: null,
                                checklist_old_id: null,
                                uploaded_by: this.$store.getters['getUserDetails'].first_name
                            }
                        )
                    }
                }
            }
        });
    },
    methods: {
        foldSignatories: function () {
            this.fold_signatories = !this.fold_signatories;
        },
        autoFocusField() {
            document.getElementById('paste_area').focus();
        },
        showCounter: function (item) {
            let c1 = this.new_version_signatories_v2.filter(x => x.purpose_new == item.purpose_new 
                        && !x.parent
                        && x.document_signature_requirements.length == 0
                    ).length;
            let c2 = this.new_version_signatories_v2.filter(y => y.purpose_new == item.purpose_new && !y.parent).reduce((acc, parent) => {
                        const hasMatch = parent.document_signature_requirements.some(child => this.checked_requirements_id_new_version.map(item => parseInt(item.split('@param@')[0], 10)).includes(child.id));
                        return acc + (hasMatch ? 1 : 0);
                    }, 0)
            
            return c1 + c2;
        },
        parentRowClicked: function (item, index, column, event) {
            if(item.parent) {
                this.new_version_signatories_v2.forEach(function (x) {
                    if(x.purpose_new == item.purpose_new && !x.parent) {
                        if(!x.hidden) {
                            x.hidden = true; // Update the age key directly
                        } else {
                            x.hidden = false;
                        }
                    }
                });
            }
        },
        parentRowClickedInternal: function (item, index, column, event) {
            if(item.parent) {
                this.internal_files_v2.forEach(function (x) {
                    if(x.reference_number == item.reference_number && !x.parent) {
                        if(!x.hidden) {
                            x.hidden = true; // Update the age key directly
                        } else {
                            x.hidden = false;
                        }
                    }
                });
            }
        },
        confirmSignatorySelection: function () {
            let selected_signatory_in_new_version = [];
            let temp_signatories = this.new_version_signatories.flatMap(x => x.template_signatory)
            for (let index = 0; index < temp_signatories.length; index++) {
                const signatory = temp_signatories[index];
                signatory.name = `${signatory.user.first_name} ${signatory.user.last_name}`
                signatory.purpose = signatory.purpose_new.purpose
                if(signatory.document_signature_requirements.length == 0) {
                    selected_signatory_in_new_version.push(signatory)
                } else if (this.selected_requirement_id_of_signatory.some(value => signatory.document_signature_requirements.some(obj => obj.id === value))) {
                    selected_signatory_in_new_version.push(signatory)
                }
            }
            let new_check=[];
            for (let index = 0; index < this.checked_requirements_id_new_version.length; index++) {
                const element = this.checked_requirements_id_new_version[index];
                let str = element.replaceAll('@param@', '_')
                new_check.push(str);
            }
            this.checked_requirements_id = new_check;
            this.signatories = selected_signatory_in_new_version;
            this.show_signatory_selection_modal = false;
        },
        closeSignatorySelectionModal: function () {
            this.show_signatory_selection_modal = false;
            // this.checked_requirements_id_new_version = []
        },
        changeTabSignatorySelection: function (item) {
			this.current_tab_signatory_selection = item
        },
        showSignatorySelection: function () {
            this.getNewVersionSignatory(this.routing.document_template_id).then(response => {
                if(response.status == 200) {
                    this.show_signatory_selection_modal = true 
                    this.new_version_signatories = response.data.data
                    this.$Progress.finish();
                }
                this.is_getting_signatories = false;
            })
        },
        selectChangeChecklistOnListExt: function (item, index) {
            DocumentRoutingJS.removeSingleInstance(this.tagged_attachments_id, item.checklist_old_id)
            this.tagged_attachments_id.push(item.checklist.id)
            this.external_files_list.filter((object) => {
                if(object.checklist && object.checklist.id == item.checklist.id && object.filename == item.filename) {
                    object.checklist_old_id = item.checklist.id;
                }
            })
        },
        selectChangeChecklistOnListInt: function (item, index) {
            DocumentRoutingJS.removeSingleInstance(this.tagged_attachments_id, item.checklist_old_id)
            this.tagged_attachments_id.push(item.checklist.id)
            this.internal_files_v2.filter((object) => {
                if(object.checklist && object.checklist.id == item.checklist.id && object.filename == item.filename) {
                    object.checklist_old_id = item.checklist.id;
                }
            })
        },
        selectChangeChecklistOnList: function (item, index) {
            let arr = this.tagged_attachments_id.filter(obj => obj !== parseInt(item._checklist_old_id));
            this.tagged_attachments_id = arr;
            this.tagged_attachments_id.push(item._checklist.id)
            this.internal_files.filter((object) => {
                if(object._checklist.id == item._checklist.id) {
                    object._checklist_old_id = item._checklist.id;
                }
            })
        },  
		changeTab: function(item) {
			this.active_tab = item
            if(item == 2) {

                if(this.external_files_list.filter(x => x.customRow).length == 0) {
                    this.external_files_list.push(
                        {
                            customRow: true,
                            _classes: `parent-row-ext ${this.$store.getters.getDarkMode ? 'parent-row-ext-dark' : 'parent-row-ext-light'}`,
                        },
                        // {
                        //     customRow: true,
                        //     file: "",
                        //     filetype: "",
                        //     filename: "",
                        //     filesize: "",
                        //     remarks: "",
                        //     checklist: "",
                        //     checklist_old_id: "",
                        //     uploaded_by: "",
                        // }
                    )
                } 
                
                this.$nextTick(() => {
                    this.autoFocusField()
                })
            }
		},
        clearExternalChecklist: function (item) {
            item.checklist = null;
            DocumentRoutingJS.removeSingleInstance(this.tagged_attachments_id, item.checklist_old_id)
            item.checklist_old_id = null;
        },
        clearInternalChecklist: function (item) {
            item.checklist = null;
            DocumentRoutingJS.removeSingleInstance(this.tagged_attachments_id, item.checklist_old_id)
            item.checklist_old_id = null;
        },
        removeExternalAttachment: function (item, index) {
            let f = this.external_files_list.indexOf(item);
            this.external_files_list.splice(f, 1);
            
            if(item.checklist) {
                DocumentRoutingJS.removeSingleInstance(this.tagged_attachments_id, item.checklist_old_id)
            }
        },
        onFileChange:function (e) {
            let files = e.target.files || e.dataTransfer.files;
            if(files.length > 0) {
                for (let index = 0; index < files.length; index++) {
                    const element = files[index];
                    if(element.type.split('/')[0] != 'image' 
                        && element.type != 'application/pdf' 
                            && element.type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
                                && element.type != 'application/vnd.ms-excel') 
                    {
                        this.$swal({
                            customClass: 'ta-left',
                            toast: true,
                            position: 'top-right',
                            timer: 5000,
                            html: `<div class="swal2-header">
                                    <ul class="swal2-progress-steps" style="display: none;"></ul>
                                    <div class="swal2-icon swal2-error swal2-icon-show" style="display: flex;">
                                        <span class="swal2-x-mark">
                                            <span class="swal2-x-mark-line-left"></span>
                                            <span class="swal2-x-mark-line-right"></span>
                                        </span>
                                    </div>
                                    <h5>Files Invalid. <br>Only accept pdf, images, and the latest excel file.</h5>
                                </div>`,
                            showConfirmButton: false,
                            timerProgressBar: true,
                        })
                        continue;
                    }


                    if (element.size / Math.pow(1024,1) > 8000) {
                        this.$swal({
                            customClass: 'ta-left',
                            toast: true,
                            position: 'top-right',
                            timer: 5000,
                            html: `<div class="swal2-header">
                                    <ul class="swal2-progress-steps" style="display: none;"></ul>
                                    <div class="swal2-icon swal2-error swal2-icon-show" style="display: flex;">
                                        <span class="swal2-x-mark">
                                            <span class="swal2-x-mark-line-left"></span>
                                            <span class="swal2-x-mark-line-right"></span>
                                        </span>
                                    </div>
                                    <h5>${element.name.length > 10 ? element.name.substring(0, 10) + '...' +  element.name.split('.')[1] : element.name} is  too large. Maximum size per file is 8MB.</h5>
                                </div>`,
                            showConfirmButton: false,
                            timerProgressBar: true,
                        })
                        continue;
                    } 

                    this.external_files_list.push(
                        {
                            customRow: false,
                            file: element,
                            filetype: element.type,
                            filename: element.name,
                            filesize: this.formatBytes(element.size),
                            remarks: '',
                            checklist: null,
                            checklist_old_id: null,
                            uploaded_by: this.$store.getters['getUserDetails'].first_name,
                            to_be_compared:0,
                        }
                    )
                }
            }
            
            const input = this.$refs._externalFiles;
            input.type = 'text';
            input.type = 'file';
        },
        inputSearch: function ( list) { 
            this.large_list_modal_key+1;
            this.large_list = list;
            this.large_list_modal = true;
        },
        largeListSelectedItem: function (item, index, column, event) {
            this.selected_reference = item.reference_number
            this.selected_id = item.id
            this.large_list_modal = false;
        },
        checkIfStateHasBase64PDF: function () {
            if(this.$store.getters.getPdfBase64) {
                this.main_file_via_form_data = true;
                let pdfb64 = `data:application/pdf;base64,${this.$store.getters.getPdfBase64}`;
                this.template_file_via_form_data = this.generatePDFFromBase64(pdfb64);
                let main_file = {
                    name: `${this.$store.getters.getPdfReferenceNumber}`+'.pdf',
                    type: "application/pdf",
                    size: this.template_file_via_form_data.size,
                }
                this.template_file.push(main_file)
                this.routing.reference_number = this.$store.getters.getPdfReferenceNumber;
                this.reference_from_form_data = true;
                this.$store.commit("CLEAR_FORM_DATA_PDF_BASE64");
            }
        },
        addExternalAttachmentSlot: function () {
            this.external_file_max_file += parseInt(this.additional_slot)
        },
        openUploadFileViaURLModal: function (){
            this.$refs._uploadFileViaURLModal.modal_form = true;
            this.$refs._uploadFileViaURLModal.getFields();
            this.$refs._uploadFileViaURLModal.template = this.selected_user_template;
        },
        confirmedDocument: function (object){
            this.template_file.push(object.file)
            this.main_file_via_url = true;
            if(object.reference_number) {
                this.routing.reference_number = object.reference_number
            }
        },
        addTemplateFileRemarks: function () {
            this.template_file_remarks_added = true;
            this.template_file_remarks.push({
                owner: this.$store.getters['getUserDetails'].id,
                value: ''
            })
        },
        addSignatoryRemarks: function () {
            this.signatory_remarks_key++;
            this.template_file_remarks_added = true;
            let row = {
                id: null,
                recently_added: true,
                owner_id: this.$store.getters.getUserDetails.id,
                remarks_by: this.$store.getters.getUserDetails.first_name,
                date: null,
                remarks: '',
            }
            this.signatory_remarks.push(row);
        },
        deleteTemplateFile: function (file) {
            if(this.main_file_via_form_data && file.lastModified == undefined) {
                this.routing.reference_number = ''
                this.reference_from_form_data = false;
                this.main_file_via_form_data = false;
            }

            var k = this.template_file.indexOf(file);
            if (k !== -1) {
                this.template_file.splice(k, 1);
            } 
            if(this.main_file_via_url) {
                this.routing.reference_number = null
                this.main_file_via_url = false;
            }
        },
        removeAttachmentFile: function(file) {
            let f = this.external_files.indexOf(file);
            if (f !== -1) {
                this.external_file_remarks.splice(f, 1)
                this.external_files.splice(f, 1);
            }
        },
        checkIfValid: function (fieldName) {
            const field = this.$v.routing[fieldName]
            if (!field.$dirty) {
                return null
            } 
            return !(field.$invalid || field.$model === '')
        },
        validateRouting: function () // JS Validation
        {
            if( !this.routing.reference_number ||
                !this.routing.document_template_id ||
                this.routing.lines <= 0
                ) return false;
            return true;
        },
        getUserTemplates: function () {
            axios.get('/drs/document-template/user-templates-list', {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.user_templates_lists = [];
                    for (let index = 0; index < response.data.data.length; index++) {
                        const element = response.data.data[index];
                        let row = {
                            id: index+1,
                            dt_id: element.id,
                            // dtc_id: element.dtc_id,
                            dtcs_id: element.dtcs_id,
                            tsm_id: element.tsm_id,
                            name: element.name,
                            document_template_category: element.document_template_category,
                            document_template_category_id: element.document_template_category_id,
                            document_template_file_api: element.document_template_file_api,
                            document_template_shares: element.document_template_shares,
                            document_template_signatures: element.document_template_signatures,
                            prompt_id: element.prompt_id,
                        }
                        this.user_templates_lists.push(row)
                    }
                    this.user_templates_lists_backup = JSON.parse(JSON.stringify(this.user_templates_lists))
                    const defaultTemplate = this.user_templates_lists.find(template => template.dt_id == this.routing_suggestion.to_template_id);
                    if (defaultTemplate) {
                        this.selected_user_template = defaultTemplate;
                    }
                }
            })
            this.routing_suggestion.to_template_id = null;
        },
        getTemplates: function () {
            axios.get('/drs/document-template-category/active-list', {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    for(let i = 0; i < response.data.data.length; i++) {
                        let option = {
                            id: response.data.data[i].id,
                            category: response.data.data[i].category,
                            // document_template_signatures: response.data.data[i].document_template_signatures
                        }
                        this.templates_lists.push(option)
                    }
                }
            })
        },
        toggleEdit: function() {
            this.editTemplate = !this.editTemplate
        },
        toggleDetails: function (item, index) {
            this.$set(this.signatories[index], '_toggled', !item._toggled)
        },
        confirmChanges: function() {
            let list = this.signatories;
            let is_empty = list.filter(x => {return !x.order});
            if(is_empty.length > 0) {
                return this.$swal({
                    toast: true,
                    position: 'top-right',
                    timer: 3000,
                    icon: 'error',
                    title: `Order field cannot be empty.`,
                    showConfirmButton: false,
                    timerProgressBar: true,
                })
            }

            let has_duplicate_order = list.some((item, index, arr) =>
                arr.findIndex(other => parseInt(other.order) == parseInt(item.order)) !== index
            );

            if(has_duplicate_order) {
                return this.$swal({
                    toast: true,
                    position: 'top-right',
                    timer: 3000,
                    icon: 'error',
                    title: `Order cannot be duplicate`,
                    showConfirmButton: false,
                    timerProgressBar: true,
                })
            }
            // let order_value = [];
            // for (let i = 0; i < list.length; i++) {
            //     // const input_new_value = document.getElementById("item_order_"+list[i].id).value;
            //     const input_new_value = this.order_input[i];
            //     if(!input_new_value) {
            //         return this.$swal({
            //             toast: true,
            //             position: 'top-right',
            //             timer: 3000,
            //             icon: 'error',
            //             title: `Order field cannot be empty.`,
            //             showConfirmButton: false,
            //             timerProgressBar: true,
            //         })
            //     }
            //     order_value.push(input_new_value)
            // }
            // if(order_value.some(x => order_value.indexOf(x) !== order_value.lastIndexOf(x))) {
            //     return this.$swal({
            //         toast: true,
            //         position: 'top-right',
            //         timer: 3000,
            //         icon: 'error',
            //         title: `Order cannot be duplicate`,
            //         showConfirmButton: false,
            //         timerProgressBar: true,
            //     })
            // } else {        
            //     for (let i = 0; i < list.length; i++) {
            //         // const value = document.getElementById("item_order_"+list[i].id).value;
            //         const value = this.order_input[i];
            //         list[i].order = value;
            //     }
            // }
            this.editTemplate = false
            list.sort(function (x, y) {
                return x.order - y.order;
            });

        },
        startRouting: function () {
            
            if(this.withWarnings()) {
                return;
            }


            // if(this.has_required_attachments) {
            //     let _required_attachment_id = this.selected_user_template.document_template_category.document_template_category_attachment_checklist.filter(x => parseInt(x.required) == 1).map(x => x.id);
            //     let _tagged_attachments_id = this.tagged_attachments_id
            //     if (_required_attachment_id.size !== _tagged_attachments_id.size) {
            //         return this.$swal({
            //             toast: true,
            //             position: 'top-right',
            //             timer: 3000,
            //             icon: 'error',
            //             title: `Some required attachments have not been tagged.`,
            //             showConfirmButton: false,
            //             timerProgressBar: true,
            //         }) 
            //     }

            //     const sortedArr1 = _required_attachment_id.slice().sort();
            //     const sortedArr2 = _tagged_attachments_id.slice().sort();

            //     // Compare sorted arrays
            //     for (let i = 0; i < sortedArr1.length; i++) {
            //         if (sortedArr1[i] !== sortedArr2[i]) {
            //             return this.$swal({
            //                 toast: true,
            //                 position: 'top-right',
            //                 timer: 3000,
            //                 icon: 'error',
            //                 title: `Some required attachments have not been tagged.`,
            //                 showConfirmButton: false,
            //                 timerProgressBar: true,
            //             }) 
            //         }
            //     }
            // }


            // if( this.template_file.length <= 0 ) {
            //     return this.$swal({
            //         toast: true,
            //         position: 'top-right',
            //         timer: 3000,
            //         icon: 'error',
            //         title: 'Please upload the selected file.',
            //         showConfirmButton: false,
            //         timerProgressBar: true,
            //     });
            // } 
            let added_lines=[];
            // let requirements_ids=[];

            // for (let k = 0; k < this.selected_user_template.document_template_signatures.length; k++) {
            //     if(this.selected_user_template.document_template_signatures[k].document_signature_requirements.length == 0) {
            //         let row = {
            //             order: this.selected_user_template.document_template_signatures[k].order,
            //             document_template_signature_id: this.selected_user_template.document_template_signatures[k].id,
            //         }
            //         added_lines.push(row) 
            //     }
            // }

            // for (let j = 0; j < this.checked_requirements_id.length; j++) {
            //     const array_id = this.checked_requirements_id[j].split("_");
            //     for (let k = 0; k < this.selected_user_template.document_template_signatures.length; k++) {
            //         let child_lists = this.selected_user_template.document_template_signatures[k].document_signature_requirements
            //         for (let l = 0; l < child_lists.length; l++) {
            //             if(array_id[0] == child_lists[l].id && array_id[1] == child_lists[l].document_signature_id) {
            //                 requirements_ids.push(array_id[0])
            //             }
            //         }
            //         if(array_id[1] == this.selected_user_template.document_template_signatures[k].id) {
            //             let row = {
            //                 signature_requirement_ids: requirements_ids,
            //                 order: this.selected_user_template.document_template_signatures[k].order,
            //                 document_template_signature_id: this.selected_user_template.document_template_signatures[k].id,
            //             }
            //             if (!added_lines.filter(e => e.signature_requirement_ids === row.signature_requirement_ids && 
            //                             e.document_template_signature_id === row.document_template_signature_id && 
            //                             e.order === row.order ).length > 0) {
            //                 added_lines.push(row)
            //                 requirements_ids=[];
            //             }
            //         }
            //     }
            // }
            if(!this.using_new_template_version) {
                added_lines = this.linesManipulation(this.selected_user_template.document_template_signatures, this.checked_requirements_id)
            } else {
                added_lines = this.linesManipulation(this.new_version_signatories_v2.filter(x => !x.parent), this.checked_requirements_id_new_version)
            }

            added_lines =  added_lines.sort((a,b)=> (parseInt(a.order) - parseInt(b.order)))

            let obj = {}
            added_lines.forEach((item, index)=>{
                item.order = index+1
                if(obj[item.document_template_signature_id]){
                        let sample = obj[item.document_template_signature_id].signature_requirement_ids + "," +item.signature_requirement_ids
                        obj[item.document_template_signature_id].signature_requirement_ids = sample.split(",")
                }else{
                    obj[item.document_template_signature_id] = item
                }
            })
            let valuesArr = Object.values(obj)
            this.routing.lines = valuesArr



            if(this.validateRouting()) { 

                var tbl = '';
                tbl += `<table border=1 class="table table-bordered table-sm" style="${this.$store.getters.getDarkMode ? 'color:white;' : 'color:#545454;'}">`;
                tbl += '<thead>';
                tbl += '<tr style="font-size: 1rem;">';
                tbl += '<td>Signatories</td><td>Requirements</td>';
                tbl += '</tr>';
                tbl += '</thead>';
                tbl += '<tbody>';
                if(!this.using_new_template_version) {
                    for (let i = 0; i < this.checked_requirements_id.length; i++) {
                        let statement = this.checked_requirements_id[i].split("_")
                        tbl += `<tr style="font-size: 12px;"><td style="text-align: left">${statement[3]}</td><td style="text-align: left">${statement[2]}</td></tr>`
                    }
                } else {
                    for (let i = 0; i < this.checked_requirements_id_new_version.length; i++) {
                        let statement = this.checked_requirements_id_new_version[i].split("@param@")
                        tbl += `<tr style="font-size: 12px;"><td style="text-align: left">${statement[3]}</td><td style="text-align: left">${statement[2]}</td></tr>`
                    }
                }
                    
                tbl += '</tbody>';
                tbl += '</table>';

                this.mergedAttachments = [...this.external_files_list.filter((item) => item.customRow == false), ...this.internal_files_v2.filter((item) => item.attachment_id != null)];
                
                var attachment_tbl = '';
                attachment_tbl += `<table border=1 class="table table-bordered table-sm" style="${this.$store.getters.getDarkMode ? 'color:white;' : 'color:#545454;'}">`;
                attachment_tbl += '<thead>';
                attachment_tbl += '<tr style="font-size: 1rem;">';
                attachment_tbl += '<td>Filetype</td><td>Filename</td><td>Select File</td>';
                attachment_tbl += '</tr>';
                attachment_tbl += '</thead>';
                attachment_tbl += '<tbody>';
                for (let i = 0; i < this.mergedAttachments.length; i++) {
                    const file = this.mergedAttachments[i];
                    if(!file.customRow) {
                        attachment_tbl += '<tr>';
    
                        attachment_tbl += '<td>';
                        if (file.filetype.split('/')[0] === 'image') {
                            attachment_tbl += `<img class="attachment-icon" src="/static/img/image-attachment-placeholder.jpg" alt="Image File"/>`;
                        } else if (file.filetype === 'application/pdf') {
                            attachment_tbl += `<img class="attachment-icon" src="/static/img/pdf-attachment-placeholder.png" alt="PDF File"/>`;
                        } else {
                            attachment_tbl += `Unknown`;
                        }
                        attachment_tbl += '</td>';
    
                        attachment_tbl += `<td title="${file.filename || 'N/A'}">`;
                        attachment_tbl += `${file.filename && file.filename.length > 20 ? file.filename.substring(0, 20) + '...' : file.filename || 'N/A'}`;
                        attachment_tbl += `</td>`;
    
                        attachment_tbl += '<td>';
                        attachment_tbl += `
                            <input 
                                type="checkbox" 
                                name="attachment_file_select" 
                                value="${file.filename}" 
                                onclick="vm.selectFile(${i})"
                                ${file.to_be_compared === 1 ? 'checked' : ''}
                            >                    
                        `;
                        attachment_tbl += '</td>';
    
                        attachment_tbl += '</tr>';
                    }
                }
                attachment_tbl += '</tbody>';
                attachment_tbl += '</table>';
                
                let eytstee = '';
                if(!this.using_new_template_version) {
                    eytstee = `<h4>You are trying to start the routing.</h4>
                    Please be certain, Once this routing <b>started</b> it will not be <i>editable</i> and unable to be <i>undone</i>.<br><br>
                    ${this.checked_requirements_id.length > 0 ? ` The following table contains of checked signature requirements.<br><br>${tbl} `: ''}
                    ${this.mergedAttachments.length > 1 && this.routing.prompt_id ? ` <b>Choose an attachment file for comparison</b><br><br>${attachment_tbl}`: ''}
                    `
                } else {
                    eytstee = `<h4>You are trying to start the routing.</h4>
                    Please be certain, Once this routing <b>started</b> it will not be <i>editable</i> and unable to be <i>undone</i>.<br><br>
                    ${this.checked_requirements_id_new_version.length > 0 ? ` The following table contains of checked signature requirements.<br><br>${tbl} `: ''}
                    ${this.mergedAttachments.length > 1 && this.routing.prompt_id ? ` <b>Choose an attachment file for comparison</b><br><br>${attachment_tbl}`: ''}
                    `
                }

                return this.$swal({
                    
                    icon: "warning",
                    title: "Are you sure?",
                    html: eytstee,
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes",
                    showLoaderOnConfirm: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    didOpen: () => {
                        const confirmButton = document.querySelector('.swal2-confirm');
                        if (confirmButton && this.mergedAttachments.length > 1 && this.routing.prompt_id) {
                            confirmButton.disabled = this.is_start_routing_confirm_button;

                            this.$watch(
                                'is_start_routing_confirm_button',
                                (newVal) => {
                                    confirmButton.disabled = newVal;
                                }
                            );
                        }
                    },
                    preConfirm: () => { 
                        this.disableCheckboxes();
                        let data = new FormData();
                        data.append('document_template_id', this.routing.document_template_id);
                        if(this.routing.dtcs_id) {
                            data.append('dtcs_id', this.routing.dtcs_id);
                        }
                        data.append('reference_number', this.routing.reference_number);
                        data.append('description', this.routing.description);
                        data.append("lines", JSON.stringify(this.routing.lines))
                        
                        if(this.template_file_remarks_added) {
                            if(this.signatory_remarks[0].remarks) {
                                data.append('remarks', this.signatory_remarks[0].remarks);
                            }
                        }

                        if(!this.main_file_via_url && !this.main_file_via_form_data) {
                            for (const i of Object.keys(this.$refs._templateFile.fileRecords)) {
                                data.append('file[' + i + ']', this.$refs._templateFile.fileRecords[i].file)
                            }
                        }

                        if(this.main_file_via_url)  {
                            data.append('path', this.template_file[0].response_url)
                        }

                        if(this.main_file_via_form_data)  {
                            if(this.$refs._templateFile.fileRecords.length > 1) {
                                data.append('file[0]', this.template_file_via_form_data)
                                for (const i of Object.keys(this.$refs._templateFile.fileRecords)) {
                                    if(this.$refs._templateFile.fileRecords[i].file.lastModified != undefined) {
                                        let counter = 1;
                                        data.append('file[' + counter.toString() + ']', this.$refs._templateFile.fileRecords[i].file)
                                        counter+1;
                                    }
                                }
                            } else {
                                data.append('file[0]', this.template_file_via_form_data)
                            }
                        }

                        for (let index = 0; index < this.external_files_list.length; index++) {
                            const element = this.external_files_list[index];
                            if(!element.customRow) {
                                data.append('external_attachment[' + index + '][file]', element.file)
                                data.append('external_attachment[' + index + '][remarks]', element.remarks)
                                data.append('external_attachment[' + index + '][name]', element.filename)
                                data.append('external_attachment[' + index + '][dtcsac_id]', element.checklist_old_id ? element.checklist_old_id : 'null')
                                if(this.mergedAttachments.length >= 1){
                                    data.append('external_attachment[' + index + '][to_be_compared]', this.mergedAttachments.length > 1 ? element.to_be_compared : this.routing.prompt_id ? 1 : 0)
                                }
                            }
                        }

                        
                        for (let el = 0; el < this.internal_files_v2.length; el++) {
                            const element = this.internal_files_v2[el];
                            if(element.attachment_id) {
                                data.append('internal_attachment[' + el + '][attachment_id]', element.attachment_id)
                                data.append('internal_attachment[' + el + '][file]', element.file)
                                data.append('internal_attachment[' + el + '][name]', element.filename)
                                data.append('internal_attachment[' + el + '][dtcsac_id]', element.checklist_old_id ? element.checklist_old_id : 'null')
                                data.append('internal_attachment[' + el + '][remarks]', element.remarks)
                                if(this.mergedAttachments.length >= 1){
                                    data.append('internal_attachment[' + el + '][to_be_compared]', this.mergedAttachments.length > 1 ? element.to_be_compared : this.routing.prompt_id ? 1 : 0)
                                }
                            }
                        }

                        // for (let i = 0; i < this.checked_attachments.length; i++) {
                        //     let _iFile = this.checked_attachments[i].split('@spacer@');
                        //     let int_file = this.internal_files.filter(e => e._refNum == _iFile[3])[0];
                        //     data.append('internal_attachment[' + i + '][attachment_id]', _iFile[0])
                        //     data.append('internal_attachment[' + i + '][file]', _iFile[1])
                        //     data.append('internal_attachment[' + i + '][name]', _iFile[2])
                        //     data.append('internal_attachment[' + i + '][dtcac_id]', int_file._checklist_old_id ? int_file._checklist_old_id : '')
                        //     data.append('internal_attachment[' + i + '][remarks]', int_file._remakrs)
                        // }

                        this.$Progress.start()
                        
                        data.append('save_as_draft', 0);
                        return this.save(data).then(response => {
                            let routing_id = response.data.data.id;
                            return axios.post('/drs/document-routing/start/'+ routing_id, {validateStatus: () => true})
                            .then(response => {
                                if ( response.status == 200 ) {
                                    this.getUserNotification(); // Manual Notification Refresh
                                    this.$swal({
                                        icon: "success",
                                        title: "Success!",
                                        html: `<b>${this.routing.reference_number}</b> Routing has been started.`,
                                    })
                                    this.compareDocuments(routing_id);
                                    this.routing = this.resetForm();
                                    this.selected_user_template = null
                                    this.reference_from_form_data = false;
                                    this.new_version_signatories = [];
                                    this.using_new_template_version = false;
                                    this.checked_requirements_id_new_version = [];
                                    this.current_tab_signatory_selection = 0
                                    this.template_file = []
                                    this.external_files = []
                                    this.internal_files = []
                                    this.internal_files_v2 = []
                                    this.checked_attachments=[]
                                    this.checked_requirements_id=[]
                                    this.signatory_remarks=[]
                                    this.active_tab = 0;
                                    this.internal_files_key++;
                                    this.external_files_key++;
                                    this.external_files_list = []
                                    this.tagged_attachments_id = []
                                    this.mergedAttachments = []
                                    this.$v.$reset()
                                    this.$v.$touch()
                                    this.is_start_routing_confirm_button = true;
                                    this.$Progress.finish()
                                }
                            })
                        })
                    }
                }).then((result) => {
                    if(!result.value){
                        this.$swal('Cancelled!','','error');
                        this.is_start_routing_confirm_button = true;
                    }
                })
            }
            this.$swal({
                toast: true,
                position: 'top-right',
                timer: 3000,
                icon: 'error',
                title: 'Please fill up required fields.',
                showConfirmButton: false,
                timerProgressBar: true,
            });
            return;
        },
        save: function (data) {
            return new Promise((resolve, reject) => {
                axios.post('/drs/document-routing/store', data, {validateStatus: () => true}, { headers: {'Content-Type': 'multipart/form-data' }})
                .then(response => {
                    if ( response.status == 200 ) {
                        resolve(response);
                    }
                })
            })
        },
        withWarnings: function () {
            if(this.warnings.length > 0) {
                let x = []
                for (let i = 0; i < this.warnings.length; i++) {
                    x.push(`<li>${this.warnings[i]}</li>`)
                }
                this.$swal({
                    icon: "error",
                    title: "Routing cannot be started!",
                    html: `<ul style="text-align: left;">${x.join('')}</ul>`, 
                })
                return true;
            }
            return false;
        },
        linesManipulation: function (signatories, requirements) {
            let added_lines = [];
            let requirements_ids=[];
            for (let k = 0; k < signatories.length; k++) {
                if(signatories[k].document_signature_requirements.length == 0) {
                    let row = {
                        order: signatories[k].order,
                        document_template_signature_id: signatories[k].id,
                    }
                    added_lines.push(row) 
                }
            }

            for (let j = 0; j < requirements.length; j++) {
                let array_id;
                if(!this.using_new_template_version) {
                    array_id = requirements[j].split("_");
                } else {
                    array_id = requirements[j].split("@param@");
                }
                for (let k = 0; k < signatories.length; k++) {
                    let child_lists = signatories[k].document_signature_requirements
                    for (let l = 0; l < child_lists.length; l++) {
                        if(array_id[0] == child_lists[l].id && array_id[1] == child_lists[l].document_signature_id) {
                            requirements_ids.push(array_id[0])
                        }
                    }
                    if(array_id[1] == signatories[k].id) {
                        let row = {
                            signature_requirement_ids: requirements_ids,
                            order: signatories[k].order,
                            document_template_signature_id: signatories[k].id,
                        }
                        if (!added_lines.filter(e => e.signature_requirement_ids === row.signature_requirement_ids && 
                                        e.document_template_signature_id === row.document_template_signature_id && 
                                        e.order === row.order ).length > 0) {
                            added_lines.push(row)
                            requirements_ids=[];
                        }
                    }
                }
            }
            return added_lines;
        },
        saveAsDraft: function() {
            // if(this.has_required_attachments) {
            //     let _required_attachment_id = this.selected_user_template.document_template_category.document_template_category_attachment_checklist.filter(x => parseInt(x.required) == 1).map(x => x.id);
            //     let _tagged_attachments_id = this.tagged_attachments_id
            //     if (_required_attachment_id.size !== _tagged_attachments_id.size) {
            //         return this.$swal({
            //             toast: true,
            //             position: 'top-right',
            //             timer: 3000,
            //             icon: 'error',
            //             title: `Some required attachments have not been tagged.`,
            //             showConfirmButton: false,
            //             timerProgressBar: true,
            //         }) 
            //     }

            //     const sortedArr1 = _required_attachment_id.slice().sort();
            //     const sortedArr2 = _tagged_attachments_id.slice().sort();

            //     // Compare sorted arrays
            //     for (let i = 0; i < sortedArr1.length; i++) {
            //         if (sortedArr1[i] !== sortedArr2[i]) {
            //             return this.$swal({
            //                 toast: true,
            //                 position: 'top-right',
            //                 timer: 3000,
            //                 icon: 'error',
            //                 title: `Some required attachments have not been tagged.`,
            //                 showConfirmButton: false,
            //                 timerProgressBar: true,
            //             }) 
            //         }
            //     }
            // }

            if(this.warnings_for_saving_as_draft.length > 0) {
                let x = []
                for (let i = 0; i < this.warnings_for_saving_as_draft.length; i++) {
                    x.push(`<li>${this.warnings_for_saving_as_draft[i]}</li>`)
                }
                this.$swal({
                    icon: "warning",
                    title: "Warning!",
                    html: `<ul style="text-align: left;">${x.join('')}</ul>`, 
                })
                return;
            }

            this.mergedAttachments = [...this.external_files_list.filter((item) => item.customRow == false), ...this.internal_files_v2.filter((item) => item.attachment_id != null)];

            // if( this.template_file.length <= 0 ) {
            //     return this.$swal({
            //         toast: true,
            //         position: 'top-right',
            //         timer: 3000,
            //         icon: 'error',
            //         title: 'Please upload the selected file.',
            //         showConfirmButton: false,
            //         timerProgressBar: true,
            //     });
            // } 


            let added_lines=[];

            if(!this.using_new_template_version) {
                added_lines = this.linesManipulation(this.selected_user_template.document_template_signatures, this.checked_requirements_id)
            } else {
                added_lines = this.linesManipulation(this.new_version_signatories_v2.filter(x => !x.parent), this.checked_requirements_id_new_version)
            }

            added_lines = added_lines.sort((a,b)=> (parseInt(a.order) - parseInt(b.order)))

            let obj = {}
            added_lines.forEach((item, index)=>{
                item.order = index+1
                if(obj[item.document_template_signature_id]){
                        let sample = obj[item.document_template_signature_id].signature_requirement_ids + "," +item.signature_requirement_ids
                        obj[item.document_template_signature_id].signature_requirement_ids = sample.split(",")
                }else{
                    obj[item.document_template_signature_id] = item
                }
            })
            let valuesArr = Object.values(obj)
            this.routing.lines = valuesArr
            if(this.validateRouting()) { 
                return this.$swal({
                    icon: "warning",
                    title: "Are you sure?",
                    html: `You are trying to save this Document Routing for <b>DRAFT</b>.`,
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes",
                    showLoaderOnConfirm: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    preConfirm: () => { 
                        this.$Progress.start()

                        let data = new FormData();
                        data.append('document_template_id', this.routing.document_template_id);
                        if(this.routing.dtcs_id) {
                            data.append('dtcs_id', this.routing.dtcs_id);
                        }
                        data.append('reference_number', this.routing.reference_number);
                        data.append('description', this.routing.description);
                        data.append("lines", JSON.stringify(this.routing.lines))
                        
                        if(this.template_file_remarks_added) {
                            if(this.signatory_remarks[0].remarks) {
                                data.append('remarks', this.signatory_remarks[0].remarks);
                            }
                        }

                        if(!this.main_file_via_url && !this.main_file_via_form_data) {
                            for (const i of Object.keys(this.$refs._templateFile.fileRecords)) {
                                data.append('file[' + i + ']', this.$refs._templateFile.fileRecords[i].file)
                            }
                        }

                        if(this.main_file_via_url)  {
                            data.append('path', this.template_file[0].response_url)
                        }

                        if(this.main_file_via_form_data)  {
                            if(this.$refs._templateFile.fileRecords.length > 1) {
                                data.append('file[0]', this.template_file_via_form_data)
                                for (const i of Object.keys(this.$refs._templateFile.fileRecords)) {
                                    if(this.$refs._templateFile.fileRecords[i].file.lastModified != undefined) {
                                        let counter = 1;
                                        data.append('file[' + counter.toString() + ']', this.$refs._templateFile.fileRecords[i].file)
                                        counter+1;
                                    }
                                }
                            } else {
                                data.append('file[0]', this.template_file_via_form_data)
                            }
                        }

                        for (let index = 0; index < this.external_files_list.length; index++) {
                            const element = this.external_files_list[index];
                            if(!element.customRow) {
                                data.append('external_attachment[' + index + '][file]', element.file)
                                data.append('external_attachment[' + index + '][remarks]', element.remarks)
                                data.append('external_attachment[' + index + '][name]', element.filename)
                                data.append('external_attachment[' + index + '][dtcsac_id]', element.checklist_old_id ? element.checklist_old_id : 'null')
                                data.append('external_attachment[' + index + '][to_be_compared]', 0)
                            }
                        }

                        for (let el = 0; el < this.internal_files_v2.length; el++) {
                            const element = this.internal_files_v2[el];
                            if(element.attachment_id) {
                                data.append('internal_attachment[' + el + '][attachment_id]', element.attachment_id)
                                data.append('internal_attachment[' + el + '][file]', element.file)
                                data.append('internal_attachment[' + el + '][name]', element.filename)
                                data.append('internal_attachment[' + el + '][dtcsac_id]', element.checklist_old_id ? element.checklist_old_id : 'null')
                                data.append('internal_attachment[' + el + '][remarks]', element.remarks)
                                if(this.mergedAttachments.length >= 1){
                                    data.append('internal_attachment[' + el + '][to_be_compared]', 0)
                                }
                            }
                        }

                        // for (let i = 0; i < this.checked_attachments.length; i++) {
                        //     let _iFile = this.checked_attachments[i].split('@spacer@');
                        //     let int_file = this.internal_files.filter(e => e._refNum == _iFile[3])[0];
                        //     data.append('internal_attachment[' + i + '][attachment_id]', _iFile[0])
                        //     data.append('internal_attachment[' + i + '][file]', _iFile[1])
                        //     data.append('internal_attachment[' + i + '][name]', _iFile[2])
                        //     data.append('internal_attachment[' + i + '][dtcac_id]', int_file._checklist_old_id ? int_file._checklist_old_id : '')
                        //     data.append('internal_attachment[' + i + '][remarks]', int_file._remakrs)
                        // }


                        
                        data.append('save_as_draft', 1);
                        return axios.post('/drs/document-routing/store', data, {validateStatus: () => true}, { headers: {'Content-Type': 'multipart/form-data' }})
                        .then(response => {
                            if ( response.status == 200 ) {
                                this.$swal({
                                    icon: "success",
                                    title: "Success!",
                                    html: `${this.routing.reference_number}  has been save as <b>DRAFT</b>.`,
                                })
                                this.routing = this.resetForm();
                                this.selected_user_template = null
                                this.reference_from_form_data = false;
                                this.template_file = []
                                this.external_files = []
                                this.internal_files = []
                                this.checked_attachments=[]
                                this.checked_requirements_id=[]
                                this.signatory_remarks=[]
                                this.active_tab = 0;
                                this.internal_files_key++;
                                this.external_files_key++;
                                this.external_files_list = []
                                this.tagged_attachments_id = []
                                this.mergedAttachments = [];
                                this.$v.$reset()
                                this.$v.$touch()
                                this.$Progress.finish()
                            }
                        })
                    }
                }).then((result) => {
                    if(!result.value){
                        this.$swal('Cancelled!','','error');
                    }
                })
            }
            this.$swal({
                toast: true,
                position: 'top-right',
                timer: 3000,
                icon: 'error',
                title: 'Please fill up required fields.',
                showConfirmButton: false,
                timerProgressBar: true,
            });
            return;
        },
        resetForm: function () {
            this.template_file_remarks_added= false,
            this.template_file_remarks = [];
            this.external_files_list = [];
             return  {
                reference_number: "",
                document_template_id: "",
                dtcs_id: "",
                lines: [],
            }
        },
        getReference:function() {
            this.selected_template_reference_number = []
            axios.get('/drs/document-routing/reference-number/'+this.selected_template.id, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    for(let i = 0; i < response.data.data.length; i++) {
                        let option = {
                            id: response.data.data[i].id,
                            reference_number: response.data.data[i].reference_number,
                        }
                        this.selected_template_reference_number.push(option)
                    }
                }
            })
        },
        addInternalFile: function(type) {

            if(this.routing_suggestion.attachment_id && 
               this.routing_suggestion.from_template_id && 
               this.routing_suggestion.reference_number) 
            {
                this.selected_template = this.routing_suggestion.from_template_id
                this.selected_reference = this.routing_suggestion.reference_number
                this.selected_id = this.routing_suggestion.attachment_id
            }

            if( !this.selected_template ||
                !this.selected_reference ) {
                return this.$swal({
                    toast: true,
                    position: 'top-right',
                    timer: 3000,
                    icon: 'error',
                    title: 'Please select reference number.',
                    showConfirmButton: false,
                    timerProgressBar: true,
                }) 
            }

            if(this.internal_files_v2.filter(x => x.reference_number == this.selected_reference).length > 0) {
                return this.$swal({
                    toast: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    icon: 'error',
                    title: `Reference number <b>${this.selected_reference}</b> already exists.`,
                    showConfirmButton: false,
                    timerProgressBar: true,
                }) 
            }
            this.is_getting_file_to_add_internally = true;
            let document_routing_id = this.selected_id;
            let internal_attachment_remarks = this.selected_reference_remarks;
            this.$Progress.start()
            axios.get('/drs/document-routing/attachments/'+document_routing_id, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    let data = response.data.data
                    let parent_file = {
                        parent: true, 
                        main:false,
                        reference_number: data.reference_number, 
                        attachment_id: null,
                        filename: "", 
                        file:"",
                        filetype: "", 
                        filesize: "", 
                        fileext: "", 
                        remarks: "", 
                        checklist: null,
                        checklist_old_id: null,
                        _classes: `parent-row ${this.$store.getters.getDarkMode ? 'parent-row-dark' : 'parent-row-light'}`,
                        url: null,
                        hidden: false,
                    }
                    this.internal_files_v2.push(parent_file) // push parent file
                    let main_file = {
                        parent: false, 
                        main: true,
                        reference_number: data.reference_number,
                        attachment_id: data.id,
                        filename: data.reference_number,
                        file: data.file,
                        filetype:"application/pdf",
                        filesize: this.formatBytes(data.file_size),
                        fileext: "pdf", 
                        remarks: internal_attachment_remarks ? internal_attachment_remarks : '',
                        checklist: null,
                        checklist_old_id: null,
                        _classes: '',
                        hidden: false,
                        to_be_compared:0,
                        url: `${this.$backend_url_with_auth}/file-viewer/internal-attachments/${document_routing_id}/${data.reference_number}?token=${this.$store.getters.getUserToken}&org_key=${this.$store.getters.getOrganizationKey}`
                        
                    }
                    this.internal_files_v2.push(main_file) // push main file
                    if(type == 'all_file') { // all file 
                        let extra_file = data.document_routing_attachments
                        for (let i = 0; i < extra_file.length; i++) {
                            let supporting_file_row = {
                                parent: false, 
                                main: false,
                                reference_number: data.reference_number,
                                attachment_id: data.id,
                                filename: extra_file[i].name,
                                file: extra_file[i].file,
                                filetype: extra_file[i].file_mime,
                                filesize: this.formatBytes(extra_file[i].file_size),
                                fileext: extra_file[i].file_ext, 
                                remarks: internal_attachment_remarks ? internal_attachment_remarks : '',
                                checklist: null,
                                checklist_old_id: null,
                                _classes: '',
                                hidden: false,
                                to_be_compared:0,
                                url: `${this.$backend_url_with_auth}/file-viewer/external-attachments/${extra_file[i].id}/${document_routing_id}/${DocumentRoutingJS.encodedAndCleanFileName(extra_file[i].name)}?token=${this.$store.getters.getUserToken}&org_key=${this.$store.getters.getOrganizationKey}`,
                            }
                            this.internal_files_v2.push(supporting_file_row); // push supporting file
                        }
                    }
                    this.$Progress.finish()
                }
            })
            
            this.selected_template = null,
            this.selected_reference = null;
            this.selected_reference_remarks = null;
            this.selected_id = null;
            this.routing_suggestion.attachment_id = null;
            this.routing_suggestion.from_template_id = null;
            this.routing_suggestion.reference_number = null;
            
            this.is_getting_file_to_add_internally = false;
            return;
        },
        removeInternalAttachment: function (item, index) {
            for (let j = 0; j < item.attachments.length; j++) {
                let data = item.attachments[j]
                let val = data.attachment_id+'~'+data.file+'~'+data.name+'~'+item._refNum
                let q = this.checked_attachments.indexOf(val)
                this.checked_attachments.splice(q, 1);
            }
            let f = this.internal_files.indexOf(item);
            this.internal_files.splice(f, 1);
            
            if(item._checklist) {
                let arr = this.tagged_attachments_id.filter(obj => obj !== parseInt(item._checklist.id));
                this.tagged_attachments_id = arr;
            }

        },
        removeInternalFileV2(type, item, index) {
            let group_internal_base_on_item = this.internal_files_v2.filter(f => f.reference_number == item.reference_number)
            if(type == 'parent_file') { // all file
                let get_checklist_old_id_in_the_childs = group_internal_base_on_item.filter(j => j.checklist_old_id).map(j => j.checklist_old_id);
                for (let y = 0; y < get_checklist_old_id_in_the_childs.length; y++) {
                    const element = get_checklist_old_id_in_the_childs[y];
                    DocumentRoutingJS.removeSingleInstance(this.tagged_attachments_id, element)
                }
                this.internal_files_v2 = this.internal_files_v2.filter(x => x.reference_number != item.reference_number);
            } else if (type == 'main_file') { // main file
                this.internal_files_v2.splice(index, 1)
            } else { // supporting file
                this.internal_files_v2.splice(index, 1)
            }

            if(group_internal_base_on_item.length == 2) {
                this.internal_files_v2 = this.internal_files_v2.filter(h => h.reference_number != item.reference_number)
            }

            if(item.checklist) {
                DocumentRoutingJS.removeSingleInstance(this.tagged_attachments_id, item.checklist_old_id)
            }
        },
        toggleInternalAttachment: function(item, index) {
            this.$set(this.internal_files[index], '_toggled', !item._toggled)
        },
        getAttachmentChecklist: function (dtcs) {
            this.$Progress.start();
            return new Promise((resolve, reject) => {
                let data = {
                    using_set: true,
                    dtcs_id: dtcs
                }
                axios.post('/drs/document-template-category-attachment-checklist/list', data, {validateStatus: () => true})
                .then(response => {
                    resolve (response)
                }).catch(function (err) {
                    console.log(err);
                })
            })
        },
        getNewVersionSignatory: function (id) {
            this.$Progress.start();
            return new Promise((resolve, reject) => {
                axios.get('/drs/template-signatory-matrix/details/'+id, {validateStatus: () => true})
                .then(response => {
                    resolve (response)
                }).catch(function (err) {
                    console.log(err);
                })
            })
        },
        selectFile: function (index) {
            document.querySelectorAll('input[name="attachment_file_select"]').forEach(cb => {
                cb.checked = false;
            });
            
            const checkbox = document.querySelectorAll('input[name="attachment_file_select"]')[index];
            checkbox.checked = true;

            this.mergedAttachments.forEach(file => {
                file.to_be_compared = 0;
            });

            this.mergedAttachments[index].to_be_compared = 1;
        },
        compareDocuments: function (routing_id) {
            axios.post('/drs/document-routing/compare/'+ routing_id, { validateStatus: () => true })
                .then(response => {
                    // console.log(response); 
                })
                .catch(err => {
                    console.log(err);
                });
        },
        disableCheckboxes() {
            const checkboxes = document.querySelectorAll('input[name="attachment_file_select"]');
            checkboxes.forEach(checkbox => {
                checkbox.disabled = true;
            });
        }

    },
    watch: {
        fold_signatories: function  (value) {
            if(value) {
                this.new_version_signatories_v2.filter(x => x.parent == false).forEach((x) => {
                    x.hidden = true
                })
            } else {
                this.new_version_signatories_v2.filter(x => x.parent == false).forEach((x) => {
                    x.hidden = false
                })
            }
        },
        show_signatory_selection_modal: function (val) {
            if(!val) {
                this.current_tab_signatory_selection = 0
                // this.checked_requirements_id_new_version = []
                // this.new_version_signatories = []
            }
        },
        selected_user_template: function (value){
            this.list_of_attachments = []
            this.editTemplate = false;
            this.user_templates_lists = JSON.parse(JSON.stringify(this.user_templates_lists_backup))

            
            // set tab to 0
            // clear internal
            // clear external
            // clear remarks
            this.active_tab = 0;
            this.internal_files = []
            // this.internal_files_v2 = []
            this.internal_files_key++;
            this.external_files_key++;
            this.external_files_list = []
            this.signatory_remarks = []
            this.tagged_attachments_id = []
            this.users_shared_routing_list = []

            if(value)  {
                this.routing.document_template_id = value.dt_id
                this.routing.dtcs_id = value.dtcs_id
                this.routing.prompt_id = value.prompt_id
                this.using_new_template_version = value.tsm_id ? true : false
                if(!this.using_new_template_version) {
                    this.signatories = (value.document_template_signatures ? value.document_template_signatures : [])
                } else {
                    this.signatories = [];
                }
                this.can_upload_file_via_url = value.document_template_file_api ? true : false
                this.signatory_locked = value.signatory_lock == '1' ? true : false
                //-----------------------------------------

                if(!value.dtcs_id) {
                    this.list_of_attachments = [];
                }

                this.is_getting_requirements = true;
                this.getAttachmentChecklist(value.dtcs_id).then(response => {
                    if(response.status == 200) {
                        // value.document_template_category.document_template_category_attachment_checklist = response.data.data
                        this.list_of_attachments = response.data.data
                        this.$Progress.finish();
                    }
                    this.is_getting_requirements = false;
                })

                if(this.using_new_template_version) {
                    this.is_getting_signatories = true
                    this.getNewVersionSignatory(this.routing.document_template_id).then(response => {
                        if(response.status == 200) {
                            // this.show_signatory_selection_modal = true
                            // value.document_template_category.document_template_category_attachment_checklist = response.data.data
                            // this.new_version_signatories = response.data.data
                            let temp_signatories = response.data.data;
                            let signatories = [];
                            let current_purpose = ''
                            for (let i = 0; i < temp_signatories.length; i++) {
                                const child = temp_signatories[i];
                                if(current_purpose != child.purpose_new.purpose) {
                                    current_purpose = child.purpose_new.purpose;
                                    let parent_row = {
                                        document_signature_requirements: [],
                                        parent: true,  
                                        purpose_new: child.purpose_new.purpose,     
                                        name: '',                       
                                        order: '',          
                                        required_signature: '',         
                                        requirements: "", 
                                        _classes: `parent-row ${this.$store.getters.getDarkMode ? 'parent-row-dark' : 'parent-row-light'}`,
                                    }
                                    signatories.push(parent_row)
                                    child.parent = false; // add new object to child
                                    child.name = child.user.name
                                    let new_purpose = child.purpose_new.purpose 
                                    delete child.purpose_new
                                    child.purpose_new = new_purpose
                                    child.hidden = false
                                    signatories.push(child)
                                } else {
                                    child.parent = false; // add new object to child
                                    child.name = child.user.name
                                    let new_purpose = child.purpose_new.purpose 
                                    delete child.purpose_new
                                    child.purpose_new = new_purpose
                                    child.hidden = false
                                    signatories.push(child)
                                }
                            }
                            this.new_version_signatories_v2 = signatories;
                            this.$Progress.finish();
                        }
                        this.is_getting_signatories = false;
                    })
                }

                

                for (let index = 0; index < value.document_template_shares.length; index++) {
                    const element = value.document_template_shares[index];
                    let row = {
                        id: element.id,
                        shared_by_user_id: element.shared_by_user_id,
                        added_by: 'Avante Admin',
                        shared_to_user_id: element.shared_to_user_id,
                        name: `${element.shared_to_user.first_name} ${element.shared_to_user.last_name}`,
                        recently_added: false, 
                    }
                    this.users_shared_routing_list.push(row)
                }

            } else {
                this.can_upload_file_via_url = false
                this.signatory_locked = false,
                this.routing.document_template_id = null
                this.signatories = []
                this.checked_requirements_id = []
                this.checked_requirements_id_new_version = []
                this.new_version_signatories_v2 = []
                this.edit = false
                //-----------------------------------------
                this.list_of_attachments = []
            }
        },
        selected_template: function(value) {
            if(value) {
                this.getReference();
                this.selected_reference = null;
                this.selected_id = null;
                this.selected_reference_remarks = null;
            } else {
                this.selected_reference = null;
                this.selected_id = null;
                this.selected_reference_remarks = null;
            }
        },
        signatories: function (value) {
            this.order_input = []
            for (let index = 0; index < value.length; index++) {
                this.order_input.push(parseInt(value[index].order))
            }
        },
        large_list_modal: function (value) {
            if(!value) {
                this.large_list_search = '';
            }
        },
        mergedAttachments: {
            deep: true,
            handler(newVal) {
                this.is_start_routing_confirm_button = !newVal.some(file => file.to_be_compared === 1);
            }
        },
    },
    
}
</script>